import React,{Component} from "react";
import "../../css/widgets/widget.css";
export default class DialogBox extends Component {
    constructor(props){
        super(props);
        this.state={
         
        
        }
        }
  render(){
    return(
    <div class="modal d-flex align-items-center justify-content-center" style={{backgroundColor:"#0000009e"}} >
        <div class="card  widget bg-white" {...this.props} style={{borderRadius:"20px"}}>
            <div style={{height:"50vh",width:"35vw",zIndex:1500,backgroundColor:"#0000009e",display:"flex",flexDirection:"row",background:"white",padding:"0"}}>  
                <div style={{height:"80%",width:"50%",paddingLeft:"3%"}}> 
                    <div class="card-header ">
                        <div class="card-caption">
                            <div class="card-icon align-items-center">
                            
                            </div>
                            <div class="card-title h2"  style={{paddingTop:"5%",color:"#4fbfbd"}}>
                                {/* <span class="main-title">WELCOME!</span> */}
                                <span class="sub-title"></span>
                            </div>
                        </div>

                        {/* <div class="action-buttons">
                            <a class="btn btn-circle d-none btn-icon-only"><i class="material-icons">cloud_download</i></a>
                            <a class="btn btn-circle btn-icon-only" onClick={()=>this.props.closeHandler()}><i class="fa fa-times"></i></a>
                        </div> */}
                    </div> 
                    <div class="card-body align-items-center d-flex justify-content-start" style={{paddingTop:"3%",color:"#4fbfbd",fontSize:"16px"}}>
                    <span class="main-title"><b>WELCOME!</b></span>
                    </div>

                    <div class="card-body h4 align-items-center d-flex justify-content-start" style={{paddingTop:"3%"}}>
                        {this.props.msg}
                    </div>

                    <div class="card-body align-items-center d-flex justify-content-start" style={{color:"gray", paddingTop:"3%"}}>
                        {this.props.msg1}
                    </div>

                    <div class="btn-group btn-group-spacer bg-white d-flex align-items-center justify-content-start" style={{maxHeight:"500px",paddingBottom:"2%",paddingTop:"10%"}}>
                        {this.props.children}
                    </div>

                </div>

                <div class="card-body align-items-center d-flex justify-content-center">
                    <img src="images/ProductTour.png" height="75%"/>
                </div>
                {/* <div class="action-buttons" style={{height:"20%",width:"3%"}}>
                    <a class="btn btn-circle d-none btn-icon-only"><i class="material-icons">cloud_download</i></a>
                    <a class="btn btn-circle btn-icon-only" onClick={()=>this.props.closeHandler()}><i class="fa fa-times"></i></a>
                </div> */}
            </div>
        </div>
    </div>
    )
  }
}

import React,{Component} from "react";
import TimePicker from 'rc-time-picker';
import axios from "../../utils/axiosInstance"
import Input from '../formcomponent/Input'
import Select  from 'react-select'
import {addScreen} from "../../redux/actions/screenAdminAction";
import moment from "moment"
import update from 'immutability-helper';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
// import {withGoogleMap, GoogleMap, Marker} from "react-google-maps"
import GoogleMapReact from 'google-map-react';

import VerificationCodeSetup from "./VerificationCodeSetup";
import {DATA_PROVIDER,SCREEN_TYPE,ZONE_TYPE,ZONE_TYPE_WADMIN,TIMEPICKER,FORM_CONFIG} from "../../../constants/CONSTANTS"
import Geocode from "react-geocode";
import {API_KEY} from '../../../../src/constants/CONSTANTS'
import {  Trans } from 'react-i18next';	
import i18n from "../../i18n";	

import {connect} from "react-redux";
// import { map } from "jquery";
@connect((store)=>{
    return {
    addScreenStatus:store.screensAdmin.addScreenStatus,
    screens: store.screensAdmin.screens,
   
}
})
export class Add_Update_Screen extends Component {
  constructor () {
   super();
   this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
   this.map = React.createRef()
   this.state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
     tags: [],
     screenIdentifier:"",
     screenName: "",
     deviceIMEI:"",
     dataProvider:"",
     scnPadding:"",
     simNumber:"",
     screenType:"",
     spotCode:"",
     address:"",
     zone:"",
     primeStartTime:"00",
     primeEndTime:"00",
     coordinates:null,
     openMap:false,
     previousDeviceIMEI:"",
     deviceIMEIDisabled:false,
     openVerificationCode:false
   };
 }

  clearForm =()=>{
    this.setState({
      screenIdentifier:"",
      screenName: "",
      deviceIMEI:"",
      dataProvider:"",
      scnPadding:"",
      simNumber:"",
      screenType:"",
      spotCode:"",
      address:"",
      zone:"",
      primeStartTime:"00",
      primeEndTime:"00",
      coordinates:null,
      tags: [],
      layout:"",
      previousDeviceIMEI:"",
      deviceIMEIDisabled:false,
    })
  }

    setFieldValue=({target})=>{

       this.setState({[target.name]:target.value})
        this.checkEnableSave();
     }

     componentWillMount(){
      var userID = JSON.parse(localStorage.getItem("userObj")).userID;
      this.setState({userID});
      this.setState({address:JSON.parse(localStorage.getItem("userObj")).address,
        coordinates:JSON.parse(localStorage.getItem("userObj")).coordinates})
        console.log("address from user ="+JSON.parse(localStorage.getItem("userObj")).address)
        console.log("coordinates from user ="+JSON.parse(localStorage.getItem("userObj")).coordinates)
     }

     componentWillReceiveProps(nextProps){
       if(nextProps.addScreenStatus==1){
         this.props.closeHandler()
       }

     }




  handleTags=(tags)=>{

  this.setState({ tags});
  }
     componentDidMount(){
     this.props.onRef(this);
     var options = { types: ['geocode'] };


  this.autocomplete = new window.google.maps.places.Autocomplete(
                        document.getElementById('screenAddress'),
                        options );//changed options to {} to get all addresses


  this.autocomplete.addListener('place_changed',
                                this.handlePlaceSelect);

       if(this.props.selectedData!=null){
        
         this.setState({
           screenIdentifier:this.props.selectedData.screenName,
           screenName: this.props.selectedData.screenName,
           deviceIMEI:this.props.selectedData.deviceIMEI,
           dataProvider:{value:this.props.selectedData.dataProvider,label:this.props.selectedData.dataProvider},
           scnPadding:this.props.selectedData.scnPadding,
           simNumber:this.props.selectedData.simNumber,
           screenType:{value:this.props.selectedData.screenType,label:this.props.selectedData.screenType},
           spotCode:{value:this.props.selectedData.spotCode,label:this.props.selectedData.spotCode},
           //spotCode:this.props.selectedData.spotCode,
           address:this.props.selectedData.address,
           coordinates:this.props.selectedData.coordinates,
           //tags:this.props.selectedData.tags.map((obj)=>{return {value:obj,label:obj}}),
           zone:{value:this.props.selectedData.zone,label:this.props.selectedData.zone},
            primeStartTime:this.props.selectedData.primeStartTime,
           primeEndTime:this.props.selectedData.primeEndTime,
          layout:this.props.selectedData.layout,
          previousDeviceIMEI:this.props.selectedData.deviceIMEI,
          deviceIMEIDisabled:this.props.selectedData.deviceIMEI.length==10 && (this.props.selectedData.deviceIMEI.startsWith("55")||this.props.selectedData.deviceIMEI.startsWith("66")
          ||this.props.selectedData.deviceIMEI.startsWith("77"))?true:false,
         })
       }
     }

     handlePlaceSelect(map,addplace) {
      console.log("dfdfdffffffff")
    // Extract City From Address Object
    let addressObject = this.autocomplete.getPlace();
    // this.map.map.setCenter();
    // this.map.map.setZoom(5);


    if (!addressObject.geometry) return;
        if (addressObject.geometry.viewport) {
          console.log(addressObject.geometry.viewport)
            this.map.map.fitBounds(addressObject.geometry.viewport);
        } else {
           this.map.map.setCenter(addressObject.geometry.location);
            this.map.map.setZoom(17);
        }

    let address = addressObject.address_components;

    // Check if address is valid
    if (address) {
      // Set State
      console.log(address);
      console.log(addressObject.geometry.location)
      this.setState(
        {
          address: addressObject.formatted_address,
          coordinates:addressObject.geometry.location
        }
      );
      console.log(this.state.coordinates)
    }
  }

  checkEnableSave = (origin,value) =>{
    console.log("checking enable value="+value)
    console.log("***this.refs.screenName.isValid()="+this.refs.screenName.isValid())
    console.log("***this.refs.deviceIMEI.isValid()="+this.refs.deviceIMEI.isValid())
    console.log("***this.state.spotCode!=''"+(this.state.spotCode!=""));
    console.log("***this.state.address"+(this.refs.address));
    console.log("***this.state.address!=''"+(this.state.address!="" && this.state.address!=undefined));
    console.log("***this.state.screenType!=''"+(this.state.screenType!=""));
    console.log("***this.state.zone!=''"+(this.state.zone!=""));
    if(this.refs.screenName.isValid() &&
    this.refs.deviceIMEI.isValid() &&
    //this.refs.scnPadding.isValid() &&
  //  ((this.state.dataProvider!="" && this.state.dataProvider.value=="Data" && this.refs.simNumber.isValid()) || (this.state.dataProvider!="" && this.state.dataProvider.value=="WIFI"))&&
    (this.state.spotCode!="" || (origin=='spotCode' && value!=="")) &&
    this.state.address!="" && this.state.address!=undefined &&
    //this.state.dataProvider!="" &&
    (this.state.screenType!="" || (origin=='screenType' && value!=="")) &&
    (this.state.zone!=""  || (origin=='zone' && value!==""))){
        console.log("SHOULD ENABLE!!")
        this.props.enableSave(true);
 }else{
    this.props.enableSave(false);
 }
 
}

getScreenDetails = () =>{
    return {
        screenIdentifier:this.props.selectedData?this.props.selectedData.screenName:null,
        screenName: this.state.screenName,
        deviceIMEI:this.state.deviceIMEI,
        dataProvider:this.state.dataProvider.value,
        scnPadding:this.state.scnPadding,
        simNumber:this.state.simNumber,
        screenType:this.state.screenType.value,
        spotCode:this.state.spotCode.value,
        address:this.state.address,
        coordinates:this.state.coordinates,
        tags:this.state.tags.map((tags)=>tags.value),
        zone:this.state.zone.value,
        primeStartTime:this.state.primeStartTime,
        primeEndTime:this.state.primeEndTime,
        layout:this.state.layout,
        deviceIMEIModified:false
      };
}

addScreen=()=>{
  console.log("this.refs.simNumber.length="+this.refs.simNumber.length)
  this.state.scnPadding=="" ? this.setState({scnPadding:0}):null
  var deviceIMEIModified = this.state.previousDeviceIMEI!==this.state.deviceIMEI?true:false;

if(this.refs.screenName.isValid() &&
this.refs.deviceIMEI.isValid() &&
//this.refs.scnPadding.isValid() &&
//((this.state.dataProvider!="" && this.state.dataProvider.value=="Data" && this.refs.simNumber.isValid()) || (this.state.dataProvider!="" && this.state.dataProvider.value=="WIFI"))&&
this.state.spotCode!="" &&
this.refs.address!="" &&
//this.state.dataProvider!="" &&
this.state.screenType!="" &&
 this.state.zone!="" &&
 this.state.primeStartTime!="" &&
 this.state.primeEndTime!="" ){
  
this.props.dispatch(addScreen({
  screenIdentifier:this.props.selectedData?this.props.selectedData.screenName:null,
  screenName: this.state.screenName,
  deviceIMEI:this.state.deviceIMEI,
  //dataProvider:this.state.dataProvider.value,
  //scnPadding:this.state.scnPadding,
  //simNumber:this.state.simNumber,
  screenType:this.state.screenType.value,
  spotCode:this.state.spotCode.value,
  address:this.state.address,
  coordinates:this.state.coordinates,
  //tags:this.state.tags.map((tags)=>tags.value),
  zone:this.state.zone.value,
  primeStartTime:this.state.primeStartTime,
  primeEndTime:this.state.primeEndTime,
  layout:this.state.layout,
  deviceIMEIModified
}))
}
}

handleDataProviderChange = (dataProvider) => {
    this.setState({ dataProvider });
    this.checkEnableSave();
  }
  handleScreenTypeChange = (screenType) => {
      this.setState({ screenType });
      this.checkEnableSave('screenType',screenType.value);
    }
  handleLocationChange = (spotCode) => {
    this.setState({ spotCode });
    this.checkEnableSave('spotCode',spotCode.value);
  }    
  handleTagChange = (tag) =>{
    console.log("tag="+tag);
    this.setState({tags:tag});
  }
  handleZoneChange = (zone) => {
      this.setState({ zone });
      this.checkEnableSave('zone',zone.value);
    }

      handleStartTimeChange = (start) => {
          this.setState({ primeStartTime:start.currentTarget.value+":00" });

        }

        handleEndTimeChange = (end) => {
            this.setState({ primeEndTime:end.currentTarget.value+":00" });

          }

validateNumber=(value)=>{
  return this.state.dataProvider!="" && this.state.dataProvider.value=="Data" && value.toString().match(/^[0][1-9]\d{9}$|^[1-9]\d{9}$/g)
}
checkScreenDuplicate=(value)=>{
  if(this.props.selectedData==null){
    return  this.props.screens.find((item)=>item.screenName.toLowerCase().trim()==value.toLowerCase())==undefined
  }else{
    if(value==this.state.screenIdentifier){
      return true
    }else{
      return  this.props.screens.filter((item)=>item.screenName!=this.state.screenIdentifier).find((item)=>item.screenName.toLowerCase().trim()==value.toLowerCase())==undefined
    }
  }

}

checkDeviceIMEIDuplicate=(value)=>{
  if(value.length!==8 && value.length!==16){
    return false;
  }
  if(this.props.selectedData==null){
    return  this.props.screens.find((item)=>item.deviceIMEI.toLowerCase().trim()==value.toLowerCase())==undefined
  }else{
    if(value==this.state.deviceIMEI){
      return true
    }else{
      return  this.props.screens.filter((item)=>item.deviceIMEI!=this.state.deviceIMEI).find((item)=>item.deviceIMEI.toLowerCase().trim()==value.toLowerCase())==undefined
    }
  }
}
onMapClicked = (props) => {
  if (this.state.showingInfoWindow) {
    this.setState({
      showingInfoWindow: true,
      activeMarker: null
    })
  }
};

onMarkerClick = (props, marker, e) =>{
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
    console.log(this.state.selectedPlace)
  }
  _mapLoaded = (mapProps, map)=> {
    map.setOptions({
       styles: [
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#dceafa'
            }
          ]
        },
        ]
    })
 }

 onMarkerDragEnd = (coord, map) => {
  const { latLng } = coord;
  const lat = latLng.lat();
  const lng = latLng.lng();
  console.log(lat,lng)
  Geocode.setApiKey(API_KEY);
  Geocode.enableDebug();

  Geocode.fromLatLng(lat, lng).then(
  response => {
    const address = response.results[0].formatted_address;
    this.setState({address:address,coordinates:{lat:lat,lng:lng}})
  },
  error => {
    console.error(error);
  }
);
};

changeStyleOnHover = ()=>{
  var verCode = document.getElementById("spanVerCode");
  verCode.style.color="#66CBC9";
}
changeStyleBack = () =>{
  var verCode = document.getElementById("spanVerCode");
  verCode.style.color="black";
}  
  

  render(){
    // console.log(this.props.selectedData.coordinates)
    const coordinates= this.props.selectedData ? this.props.selectedData.coordinates:{lat: 20.5937,
        lng: 78.9629}
        console.log(coordinates)
    // var bounds = new window.google.maps.LatLngBounds();
    // bounds.extend({lat: 20.5937,
    //   lng: 78.9629})
    // if(this.props.screens.length>0){
    //   for (var i = 0; i < this.props.screens.length; i++) {
    //       bounds.extend({lat:parseFloat(this.props.screens[i].coordinates.lat),lng:parseFloat(this.props.screens[i].coordinates.lng)});
    //   }
    // }
    // const mapStyle = [
    //   {
    //     featureType: 'landscape.man_made',
    //     elementType: 'geometry.fill',
    //     stylers: [
    //       {
    //         color: '#dceafa'
    //       }
    //     ]
    //   },
    //   ]
     
     
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    return(
        <div style={{height:"60vh",width:"55vw"}}>
      <div class="d-flex w-100  py-2" >

<div class="d-flex w-100" >
<div class="d-flex flex-column py-3 h-100 w-100">

   
<div class="form-row" style={{paddingTop:"12px",paddingBottom:"0px"}}>
   <div class="col">
   <Input
                text={i18n.t("screenmgt.screenname")	}
                ref="screenName"
                name="screenName"
                validator={true}
                validate={this.checkScreenDuplicate}
                value={this.state.screenName}
                onChange={this.setFieldValue}
              mandatory
                errorMessage={i18n.t("screenmgt.screennameerror")	}

                emptyMessage="Application name can't be empty"
              />
   </div>
   <div class="col" style={{display:"flex",flexDirection:"column",border:"0px solid green", padding:"0px"}}>
   
   <Input
                text="Verification Code"
                ref="deviceIMEI"
                name="deviceIMEI"
                validator={true}
                validate={this.checkDeviceIMEIDuplicate}
                value={this.state.deviceIMEI}
                onChange={this.setFieldValue}
              mandatory

                errorMessage={i18n.t("screenmgt.deviceimeierror")	}
                emptyMessage="Verification Code can't be empty"
                disabled={this.state.deviceIMEIDisabled}
              />
              <div id="spanVerCode" style={{paddingTop:"0px",paddingLeft:"5px",textAlign:"left",fontSize:"14px",cursor:"pointer",textDecoration:"underLine",color:'black',border:"0px solid red"}}
        onMouseOver={()=>this.changeStyleOnHover()} onMouseOut={()=>this.changeStyleBack()}
              onClick={()=>{this.setState({openVerificationCode:true})}}>
        How do I get the verification code?</div>
   </div>
 </div>
 {/* <div class="form-row" style={{paddingTop:"0px"}}>
   <div class="col-6"></div>
   <div class="col-6" style={{display:"flex",flexDirection:"column",
   justifyContent:"flex-end",alignItems:"flex-end",fontSize:"14px",cursor:"pointer",textDecoration:"underLine"}}>
    How do I get this?</div>
</div> */}
 <div class="form-row" style={{paddingTop:"12px"}}>

 <div class="col">
 {/*<Input
              text="Location"
              ref="spotCode"
              name="spotCode"
              validator={true}
              value={this.state.spotCode}
              onChange={this.setFieldValue}
            mandatory


              emptyMessage="Spot Code can't be empty"
 />*/}
 {this.props.selectedData==null?
  <Select styles={selectStyles}
  placeholder={i18n.t("screenmgt.selectlocation")	}
   options={this.props.locationData} className="reactSelect" style={{width:"100%"}} onChange={this.handleLocationChange}/>
  :
  <Select styles={selectStyles} options={this.props.locationData}
  placeholder={i18n.t("screenmgt.selectlocation")	}
  defaultValue={{value:this.props.selectedData.spotCode,label:this.props.selectedData.spotCode}}
  className="reactSelect" style={{width:"100%"}} onChange={this.handleLocationChange}/>
}
 </div>
 <div class="col">
     {this.props.selectedData==null?
     <Select styles={selectStyles}
     placeholder={i18n.t("screenmgt.selectscreentype")	}
      options={this.props.screenTypeData} className="reactSelect" style={{width:"100%"}} onChange={this.handleScreenTypeChange}/>
     :
     <Select styles={selectStyles} options={this.props.screenTypeData}
     placeholder={i18n.t("screenmgt.selectscreentype")	}
     defaultValue={{value:this.props.selectedData.screenType,label:this.props.selectedData.screenType}}
     className="reactSelect" style={{width:"100%"}} onChange={this.handleScreenTypeChange}/>
   }
     </div>
 {/* <div class="col">
 
 {this.props.selectedData==null?
  <Select styles={selectStyles}
  placeholder={i18n.t("screenmgt.tag")	}
   options={this.props.tagsData} 
   isMulti={true}
   className="reactSelect" style={{width:"100%"}} onChange={this.handleTagChange}/>
  :
  <Select styles={selectStyles} options={this.props.tagsData}
  placeholder={i18n.t("screenmgt.tag")	}
  isMulti={true}
  defaultValue={this.props.selectedData.tags && this.props.selectedData.tags.map((obj)=>{
    if(obj!==this.props.selectedData.screenType && obj!== this.props.selectedData.screenName && obj!==this.props.selectedData.spotCode
      && ["MASTER","SLAVE","TICKER"].indexOf(obj)==-1){
        return {value:obj,label:obj}
      }
      })}
    
  className="reactSelect" style={{width:"100%"}} onChange={this.handleTagChange}/>
}
 </div> */}
    {/* <div class="col">
    <Input
                 text="Screen Padding"
                 ref="scnPadding"
                 name="scnPadding"
                 type="number"
                //  validate={this.validateNumber}
                //  disabled={this.state.dataProvider?this.state.dataProvider.value=="WIFI":false}
                 validator={true}
                 value={this.state.scnPadding}
                 onChange={this.setFieldValue}
                 errorMessage="Sim number should be 10 digits"


                 emptyMessage=""
               />
    </div> */}
  </div>

   <div class="form-row my-2"  style={{paddingTop:"12px"}}>
      <div class="col-6">



      <input
                   text={i18n.t("screenmgt.address")	}
                    name="address"
                    id="screenAddress"
                    className="form-control"
                    // disabled={true}
                    value={this.state.address}
  onChange={this.setFieldValue}
                 mandatory
      />
      {/* <i onClick={()=>this.setState({openMap:true})} style={{position:"absolute", bottom:"2px", right :"5px", width:"24px",height:"24px"}} class="fa fa-map-marker" aria-hidden="true"></i> */}

        </div>
      <div class="col-6">
      {this.props.selectedData==null?
      <Select styles={selectStyles}
      placeholder={i18n.t("screenmgt.selectorientation")	}
       options={ZONE_TYPE_WADMIN} className="reactSelect" style={{width:"100%"}} onChange={this.handleZoneChange}/>

      :
      <Select styles={selectStyles} options={ZONE_TYPE_WADMIN}
      placeholder={i18n.t("screenmgt.selectorientation")	}
      defaultValue={{value:this.props.selectedData.zone,label:this.props.selectedData.zone}}
      className="reactSelect" style={{width:"100%"}} onChange={this.handleZoneChange}/>

    }
      </div>

    </div>

  {/*<Select options={options} style={{width:"100%"}} onChange={this.handleChange}/>*/}
  {/* <div class="form-row">
     <div class="col">
     {this.props.selectedData==null?
     <Select styles={selectStyles}
     placeholder={i18n.t("screenmgt.selectscreentype")	}
      options={this.props.screenTypeData} className="reactSelect" style={{width:"100%"}} onChange={this.handleScreenTypeChange}/>
     :
     <Select styles={selectStyles} options={this.props.screenTypeData}
     placeholder={i18n.t("screenmgt.selectscreentype")	}
     defaultValue={{value:this.props.selectedData.screenType,label:this.props.selectedData.screenType}}
     className="reactSelect" style={{width:"100%"}} onChange={this.handleScreenTypeChange}/>
   }
     </div> */}
     {/* <div class="col">
        {this.props.selectedData==null?
    <Select styles={selectStyles} options={DATA_PROVIDER} placeholder={i18n.t("screenmgt.selectinternetprovider")} className="reactSelect" style={{width:"100%"}} onChange={this.handleDataProviderChange}/>

    :
    <Select styles={selectStyles} options={DATA_PROVIDER}
placeholder={i18n.t("screenmgt.selectinternetprovider")	}
  defaultValue={{value:this.props.selectedData.dataProvider,label:this.props.selectedData.dataProvider}}
    className="reactSelect" style={{width:"100%"}} onChange={this.handleDataProviderChange}/>


  }
     </div> */}

   {/* </div> */}
   {/*<div class="form-row">

      <div class="col-sm-6">
      <label class="mt-3">Screen Prime Time</label>
      <div class="d-flex align-items-center">
      {this.props.selectedData==null?
      <select
      placeholder={""}
name="primeStartTime"

        className="form-control" style={{width:"70px"}} onChange={this.handleStartTimeChange}>
{TIMEPICKER.map((item,i)=>{
  if(i==0){
    return (<option selected value={item.value}>{item.label}</option>)
  }
  else{
      return (<option  value={item.value}>{item.label}</option>)
  }
})}
       </select>
       :
       <select
       placeholder={""}
       name="primeStartTime"

       className="form-control" style={{width:"70px"}} onChange={this.handleStartTimeChange}>
        {TIMEPICKER.map((item,i)=>  {
          if(this.props.selectedData.primeStartTime.split(":")[0]==item.value){
            return (<option selected value={item.value}>{item.label}</option>)
          }
          else{
              return (<option  value={item.value}>{item.label}</option>)
          }
        })}
         </select>
     }&nbsp;:00 &nbsp;
     -&nbsp;

        {this.props.selectedData==null?
      <select
      placeholder={""}
      name="primeEndTime"

      className="form-control" style={{width:"70px"}} onChange={this.handleEndTimeChange}>
      {TIMEPICKER.map((item,i)=>{
        if(i==0){
          return (<option selected value={item.value}>{item.label}</option>)
        }
        else{
            return (<option  value={item.value}>{item.label}</option>)
        }
      })}
             </select>:
      <select
      placeholder={""}
      name="primeEndTime"

      className="form-control" style={{width:"70px"}} onChange={this.handleEndTimeChange}>
      {TIMEPICKER.map((item,i)=>{
        if(this.props.selectedData.primeEndTime.split(":")[0]==item.value){
          return (<option selected value={item.value}>{item.label}</option>)
        }
        else{
            return (<option  value={item.value}>{item.label}</option>)
        }

})

    }
             </select>

    }&nbsp;:00

      </div>

    </div>
    </div>*/}

    {/* <div class="form-row my-2 py-1">
 <div class="col">
    <Input
                 text={i18n.t("screenmgt.screenpadding")	}
                 ref="scnPadding"
                 name="scnPadding"
                 type="number"
                 value={this.state.scnPadding}
                 onChange={this.setFieldValue}
                 emptyMessage=""
               />
    </div>
    <div class="col">
    <Input
                 text={i18n.t("screenmgt.simnumber")	}
                 ref="simNumber"
                 name="simNumber"
                 type="number"
                 validate={this.validateNumber}
                 minNums={10}
                 disabled={this.state.dataProvider?this.state.dataProvider.value=="WIFI":false}
                 validator={true}
                 value={this.state.simNumber}
                 onChange={this.setFieldValue}
                 errorMessage={i18n.t("screenmgt.simnumbererror")	}


                 emptyMessage=""
               />
    </div>
 </div> */}

</div>
</div>

<div style= {{paddingLeft:"10px",border:"0px solid red",height:"60vh",width:"30vw"}} >
  {/* <GoogleMapReact defaultZoom={5}
    defaultCenter={{ lat: 20.5937,
      lng: 78.9629}}>
      <AnyReactComponent
          lat={20.5937}
          lng={78.9629}
          text={'Google Map'}
        />
    </GoogleMapReact> */}
<Map ref={(ref) => { this.map = ref; }} containerStyle={{width:"100%", height:"100%",position:"relative"}}
          // bounds={bounds}
          google={window.google}
          zoom={4}
          initialCenter={coordinates}
          // onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
          onClick={this.onMapClicked}>
            <Marker 
            google={window.google}
            onClick={this.onMarkerClick}
             position={this.state.coordinates}
              icon={{'url':'https://maps.google.com/mapfiles/ms/icons/red-dot.png'}}
                name={i18n.t("screenmgt.currenlocation")	}
                draggable={true}
                onClick={this.handlePlaceSelect}
                onDragend={(t, map, coord) => this.onMarkerDragEnd(coord,map)}/>
          <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}>
            <div>
              <h1>{this.state.selectedPlace.name}</h1>
            </div>
        </InfoWindow>
          </Map>
        </div>
  </div>
  {/* <div class="d-flex justify-content-center align-items-center btn-group" style={{height:"90px"}}>
  <button class="btn btn-lite" onClick={()=>this.props.closeHandler()}><Trans i18nKey="screenmgt.cancel"/></button>
 <button class="btn btn-info" onClick={()=>this.addScreen()}><Trans i18nKey="screenmgt.save"/></button>
  </div> */}
  {this.state.openVerificationCode?<VerificationCodeSetup closeHandler={()=>this.setState({openVerificationCode:false})}/>:null}
  </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: (API_KEY),
  language:localStorage.getItem("locale")
})(Add_Update_Screen)

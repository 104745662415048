import axios from "../../utils/axiosInstance";

//import axios from "axios"
import {HOST} from "../../../constants/CONSTANTS"
import {getTokenData} from "../../utils/utility"
export function registerUser(userObj){
  return (dispatch)=>{

    axios.post(`/api/register`,userObj).then(function (response){

      })

   }
}
/*export function login(userObj){
  return (dispatch)=>{
    dispatch({type:"AUTH_INIT"})
    var url = new URL(window.location.href);
    userObj.user = url.searchParams.get("user");
    userObj.state = url.searchParams.get("state");
    userObj.time = url.searchParams.get("time");
    userObj.brand = url.searchParams.get("brand");
    userObj.extensions = url.searchParams.get("extensions");
    userObj.signatures = url.searchParams.get("signatures");
    
    axios.post(`/api/login`,userObj).then(function (response){
      if (response.status == 201){
        window.location.href = response.data; 
        return null;
        }
      localStorage.setItem("token",response.data.token)
      localStorage.setItem("userObj",JSON.stringify(response.data.userObject))
      axios.defaults.headers.common['Auth-Token'] = response.data.token;

        dispatch({type:"AUTH_SUCCESS",payload:response.data.userObject.resetPWD})
      }).catch((err)=>{
        console.log("***Err="+JSON.stringify(err))
        dispatch({type:"AUTH_FAILED",payload:{code:err.response.data.code}})
      })

   }
}*/
export function sendEmailForReset(userName,locale){
  return (dispatch)=>{
    dispatch({type:"SENDMAIL_RESET"})
    axios.post(`/api/sendEmailForReset`,{userName,locale}).then(function (response){
        dispatch({type:"SENDMAIL_RESET_SUCCESS"})
      }).catch((err)=>{
        console.log("err="+err)
        dispatch({type:"SENDMAIL_RESET_FAILED"})
      })

   }
}


export function resetPassword(userObj){
  return (dispatch)=>{
    dispatch({type:"RESET_PWD"})
    axios.post(`/api/changePassword`,userObj).then(function (response){
      localStorage.setItem("token",response.data.token)
      localStorage.setItem("userObj",JSON.stringify(response.data.userObject))
        axios.defaults.headers.common['Auth-Token'] = response.data.token;
        console.log("auth action reset password success!");
        dispatch({type:"RESET_PWD_SUCCESS",payload:response.data.userObject.resetPWD})
      }).catch((err)=>{
        dispatch({type:"RESET_PWD_FAILED"})
      })

   }
}

export function logout(){
  return (dispatch)=>{
    //axios.post(`/api/logout`);
    localStorage.removeItem("token")
    localStorage.removeItem("userObj")
 	localStorage.removeItem("minimizeImpression");
    localStorage.removeItem("locale")
    localStorage.removeItem("filterParams");
    localStorage.removeItem("aduploadProgressVisibility");
    localStorage.removeItem("filesArr");
    localStorage.removeItem("bypassStartSetup");
      dispatch({type:"UN_AUTH"})

   }
}

export function setLocaleForUser(locale){
  return (dispatch)=>{
  axios.post('/api/setLocaleForUser',{locale});
  dispatch({type:"SET_LOCALE"})
  }
}

export function redirectCanva(userObj){
  return (dispatch)=>{
    console.log("userObj="+JSON.stringify(userObj))
    axios.post('/api/redirectCanva',{userObj}).then(function (response){
      dispatch({type:"SET_LOCALE"})
    }).catch((err)=>{
      console.log("Error in Canva redirect!")
    });
    
    }
}
export function getCustomerDetails(userID){
  return (dispatch)=>{
    dispatch({type:"GET_CUSTOMERDETAILS"})
    axios.post(`/api/getCustomerDetails`,{userID}).then(function (response){
        dispatch({type:"GET_CUSTOMERDETAILS_SUCCESS",payload:response.data})
      })

   }
}

export function loginAgain(){
  return (dispatch)=>{
    localStorage.removeItem("token")
    localStorage.removeItem("userObj");
    localStorage.removeItem("locale");
    dispatch({type:"UN_AUTH"})
    dispatch({type:"LOGIN_AGAIN"});
  }
}

export function validateOTP(userID,OTP){
  return (dispatch)=>{
  axios.post('/api/validateOTP',{owner:userID,OTP}).then(function (response){
    dispatch({type:"OTP_VALIDATED",payload:response.data})
  })
  }
}

export function sendEmailForRegister(user){
  return (dispatch)=>{
    dispatch({type:"SENDMAIL_RESET"})
    axios.post(`/api/sendRegisterMail`,{user}).then(function (response){
        dispatch({type:"SENDMAIL_RESET_SUCCESS"})
      }).catch((err)=>{
        console.log("err="+err)
        dispatch({type:"SENDMAIL_RESET_FAILED"})
      })

   }
}

export function setVerificationFalse(userObj){
  console.log("Setting Verification false!!")
  return (dispatch)=>{

    axios.post(`/api/setVerification`,userObj).then(function (response){
      console.log("API Posting ==", response.status)

      })

   }
}

export function login(userObj){
  console.log("***In login="+userObj.currency)
  return (dispatch)=>{
    dispatch({type:"AUTH_INIT"})
    var url = new URL(window.location.href);
    userObj.user = url.searchParams.get("user");
    userObj.state = url.searchParams.get("state");
    userObj.time = url.searchParams.get("time");
    userObj.brand = url.searchParams.get("brand");
    userObj.extensions = url.searchParams.get("extensions");
    userObj.signatures = url.searchParams.get("signatures");
    
    axios.post(`/api/login`,userObj).then(function (response){
      console.log("Response Received in Login==>", response.data)
      
      if (response.status == 201){
        // console.log("Response Received in Login==>", response.data)
        window.location.href = response.data; 
        return null;
        } 

        if (response.status == 202){ //should redirect to My plan page
           //console.log("Response Received in Login==>", response.data.userObject)
          localStorage.setItem("token",response.data.token)
          localStorage.setItem("userObj",JSON.stringify(response.data.userObject))
          axios.defaults.headers.common['Auth-Token'] = response.data.token;
          window.location.href = response.data.redirectUrl; 
          return null;
          } 
              
        localStorage.setItem("token",response.data.token)
        localStorage.setItem("userObj",JSON.stringify(response.data.userObject))
        axios.defaults.headers.common['Auth-Token'] = response.data.token;

        dispatch({type:"AUTH_SUCCESS",payload:response.data.userObject.resetPWD})
      }).catch((err)=>{
         console.log("Error in login!!!", err.response.data.code)
         localStorage.setItem("code",err.response.data.code)
         dispatch({type:"AUTH_FAILED",payload:{code:err.response.data.code}})
        
      })

  }
}
export function updateStripeOperationCompleted(userID){
  return (dispatch)=>{
    axios.post("/api/updateStripeOperationCompleted",{userID}).then(function (response){          
      dispatch({type:"GET_CUSTOMERDETAILS_SUCCESS",payload:response.data})
      })

  }
}

export function saveSubscPeriod(subscriptionPeriod){
  return (dispatch)=>{
      dispatch({type:"SAVE_CURRENT_SUBS_PERIOD",subscriptionPeriod})
  }
}
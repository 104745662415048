import React, {Component} from "react";
//import Checkbox from "../checkbox/template_1/Checkbox"
import Checkbox5 from "../checkbox/template_5/Checkbox"

export default class DeleteSelectedLicensesRenderer extends Component {
    constructor(props) {
        super(props);
        this.state={
            //color:"",
            disabled:false,
            deleteLicense:false,
            licensesToBeDisabled:[],
        }
        this.invokeParentMethod11 = this.invokeParentMethod11.bind(this);
    }

    componentDidMount(){
        var statusForLicense = this.props.context.componentParent.getSelectedStatusForLicense(this.props.node.rowIndex);
        console.log("statusForLicense="+statusForLicense);
        var source = this.props.context.componentParent.getSource();
        this.setState({deleteLicense:statusForLicense,disabled:statusForLicense && source=='ADMIN'?true:false});
        
    }

    invokeParentMethod11() {
        console.log("***in invokeParentMethod11")
        var newDeleteLicenseStatus = !this.state.deleteLicense;
        this.setState({deleteLicense:newDeleteLicenseStatus})
        this.props.context.componentParent.deleteSelectedLicense(this.props.node.rowIndex,newDeleteLicenseStatus)
    }

    render() {
        return (
            
            <span>
                <Checkbox5 handler={(e)=>

                this.invokeParentMethod11()
        
              } id={"btnDeleteSelectedLicense_"+this.props.node.rowIndex} checked={this.state.deleteLicense}   
              disabled={this.state.disabled}
              />
              </span>
        );
    }
};

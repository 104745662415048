import axios from "../../utils/axiosInstance";
import moment from 'moment';

export function fetchUsersAdmin(loggedInUserRole) {
  console.log("in user admin="+loggedInUserRole)
  return (dispatch) => {
    dispatch({ type: "FETCH_USERS_ADMIN" })
    axios.post("/api/admin/getUsers",{loggedInUserRole}).then(function (response) {

      dispatch({type:"FETCH_USERS_ADMIN_SUCCESS",payload:response.data})
    })

  }
  }

  export function fetchDeviceIPsAdmin() {
    return (dispatch) => {
      dispatch({ type: "FETCH_DEVICEIPS_ADMIN" })
      axios.get("/api/admin/getDeviceIPs").then(function (response) {
  
        dispatch({type:"FETCH_DEVICEIPS_ADMIN_SUCCESS",payload:response.data})
      })
  
    }
    }

    export function updateDeviceIP(device) {
      return (dispatch) => {
        dispatch({ type: "UPDATE_DEVICEIPS_ADMIN" })
        axios.post("/api/device/updateDeviceIP",{
          deviceIMEI:device.deviceIMEI,
          deviceIP: device.deviceIP,
        command:device.command}).then(function (response) {
    
          dispatch({type:"UPDATE_DEVICEIPS_ADMIN_SUCCESS",payload:response.data})
        })
    
      }
      }    

      export function fetchSoftwareVersions() {
        return (dispatch) => {
          dispatch({ type: "FETCH_SOFTWARE_VERSIONS" })
          axios.get("/api/admin/getSoftwareVersions").then(function (response) {
      
            dispatch({type:"FETCH_SOFTWARE_VERSIONS_SUCCESS",payload:response.data})
          })
      
        }
        }   
        
        export function updateSoftwareVersion(user) {
          console.log("user.id="+user.id);
          console.log("user.softwareVersion="+user.softwareVersion);
          return (dispatch) => {
            dispatch({ type: "UPDATE_SOFTWARE_VERSION" })
            axios.post("/api/admin/updateSoftwareVersionForUser",{
              id:user.id,
              softwareVersion: user.softwareVersion,
              monitor_sw_version: user.monitor_sw_version}).then(function (response) {
        
              dispatch({type:"UPDATE_SOFTWARE_VERSION_SUCCESS",payload:response.data})
            })
        
          }
          }        

export function addUser(user){
  return (dispatch)=>{
        if(user.userIdentifier==null){

          dispatch({ type: "ADD_USER" })
        axios.post(`/api/admin/register`, {

          firstName: user.firstName,
          lastName:user.lastName,
          emailId:user.emailId,
          userName:user.userName,
          contactNumber:user.contactNumber,

          address:user.address,

          coordinates:user.coordinates,
          role:user.role,
          manager_id:user.manager_id,
          manager_username:user.manager_username,
          userRole:user.userRole,
          refreshInterval:user.refreshInterval,
          idleTime:user.idleTime,
		      customerAlias:user.customerAlias,
          regdCompanyName:user.regdCompanyName,    
          gracePeriod:user.gracePeriod,      
          loggedInUserRole:user.loggedInUserRole, //to determine if 000 then customerDetails to be saved
          customerType:user.customerType, 
          customerAlias:user.customerAlias,
          regdCompanyName:user.regdCompanyName	
        }
        ).then(response => {

            dispatch({ type: "ADD_USER_SUCCESS",payload:{
              data:response.data.data

            } })


        }).catch(error => {
          dispatch({ type: "ADD_USER_FAILURE" })
        });

      }else{
        dispatch({ type: "ADD_USER" })
      axios.post(`/api/admin/updateUser`, {
        userIdentifier:user.userIdentifier,
        firstName: user.firstName,
        lastName:user.lastName,
          userName:user.userName,
        emailId:user.emailId,
        contactNumber:user.contactNumber,

        address:user.address,

        coordinates:user.coordinates,
        role:user.role,
        manager_id:user.manager_id,
        manager_username:user.manager_username,
        userRole:user.userRole,
        refreshInterval:user.refreshInterval,
        idleTime:user.idleTime,
		customerAlias:user.customerAlias,
	    regdCompanyName:user.regdCompanyName,
      gracePeriod:user.gracePeriod, 
	    loggedInUserRole:user.loggedInUserRole,
	    selectedUserId:user.selectedUserId,
	    customerType:user.customerType,
      }
      ).then(response => {

          dispatch({ type: "UPDATE_USER_SUCCESS",payload:{
            data:response.data.data,userIdentifier:user.userIdentifier

          } })


      }).catch(error => {
        dispatch({ type: "ADD_USER_FAILURE" })
      });
      }

  }
}

export function activateLicenses(user){
  console.log("In activateLicenses user.licensesTaken!"+user.licensesTaken);
  return (dispatch)=>{        
        console.log("To activateLicenses!");
        dispatch({ type: "ADD_USER" })
      axios.post(`/api/admin/activateLicenses`, {
        userIdentifier:user.userIdentifier,
          firstName: user.firstName,
          lastName:user.lastName,
          userName:user.userName,
          emailId:user.emailId,
          contactNumber:user.contactNumber,
          address:user.address,
          coordinates:user.coordinates,
          role:user.role,
          manager_id:user.manager_id,
          manager_username:user.manager_username,
          userRole:user.userRole,
          customerAlias:user.customerAlias,
          regdCompanyName:user.regdCompanyName,
          startDate:user.startDate,
          expiryDate:user.expiryDate,
          licenseType:user.licenseType,
          subscriptionPeriod:user.subscriptionPeriod,
          billingValue:user.billingValue,
          proRataValue:user.proRataValue,
          approvedLicenses:user.approvedLicenses,
          gracePeriod:user.gracePeriod,
          baseCurrency:user.baseCurrency,
          amtPerLicense:user.amtPerLicense,
          //loggedInUserRole:user.loggedInUserRole, //to determine if 000 then customerDetails to be saved
          selectedUserId:user.selectedUserId,
          billinghistories:user.billinghistories,
          operation:user.operation,
          numLicensesToBeAdded:user.numLicensesToBeAdded,
          startDateForBillingHistory:user.startDateForBillingHistory,
          licensesTaken:user.licensesTaken,
          numLicensesToBeUpgraded:user.numLicensesToBeUpgraded,
      }
      ).then(response => {
        console.log("***response.data="+JSON.stringify(response.data))
        console.log("***userIdentifier:user.userIdentifier="+user.userIdentifier)
          dispatch({ type: "UPDATE_USER_SUCCESS",payload:{
            data:response.data,userIdentifier:user.userIdentifier

          } })


      }).catch(error => {
        dispatch({ type: "ADD_USER_FAILURE" })
      });
      

  }
}

export function deleteLicenses(user) {
  return (dispatch) => {
    dispatch({ type: "DELETE_LICENSES" })
    axios.post("/api/admin/deleteLicenses",{userID:user.selectedUserId,
    expiryDate:user.expiryDate,
  //numLicensesToBeDeleted:user.numLicensesToBeDeleted
  licensesToDelete:user.licensesToDelete
}).then(function (response) {
  console.log("Delete license response="+JSON.stringify(response.data))
      dispatch({type:"DELETE_LICENSES_SUCCESS",payload:{
        data:response.data}})
    })

  }
  }

export function deleteScreensForOnlineCustomer(user) {
    return (dispatch) => {
      //dispatch({ type: "DELETE_LICENSES" })
      axios.post("/api/admin/deleteScreensForOnlineCustomer",{userID:user.selectedUserId,
    licensesToDelete:user.licensesToDelete
  }).then(function (response) {
    console.log("Deleted selected licenses ")        
      })
  
    }
    }
  export function downgradeLicenses(user) {
    console.log("In action of downgradeLicenses="+user.startDate)
    return (dispatch) => {
      dispatch({ type: "DELETE_LICENSES" })
      axios.post("/api/admin/downgradeLicenses",{userID:user.selectedUserId,
        startDate:user.startDate,
        expiryDate:user.expiryDate,
        numLicensesToBeDowngraded:user.numLicensesToBeDowngraded,
        amtPerLicense:user.amtPerLicense,
        billingValue:user.billingValue,
        licenseType:user.licenseType,
        subscriptionPeriod:user.subscriptionPeriod,
        baseCurrency:user.baseCurrency}).then(function (response) {
        dispatch({type:"DOWNGRADE_LICENSES_SUCCESS"})
      })
  
    }
    }

export function fetchUsersForRole(loggedInUserRole) {
  return (dispatch) => {
    dispatch({ type: "FETCH_USERS_FOR_ROLE" })
    axios.post("/api/admin/getUsersForRole",{loggedInUserRole}).then(function (response) {
      dispatch({type:"FETCH_USERS_FOR_ROLE_SUCCESS",payload:response.data})
    })

  }
  }

  export function fetchLoggedInUserSubordinates(loggedInUserRole) {
    console.log("in user admin="+loggedInUserRole)
    return (dispatch) => {
      dispatch({ type: "FETCH_USERS_ADMIN" })
      axios.post("/api/admin/getLoggedInUserSubordinates",{loggedInUserRole}).then(function (response) {
  
        dispatch({type:"FETCH_USERS_ADMIN_SUCCESS",payload:response.data})
      })
  
    }
    }
    export function fetchLevel2UsersForLevel1() {
      return (dispatch) => {
        dispatch({ type: "FETCH_USERS_ADMIN" })
        axios.get("/api/admin/getLevel2UsersForLevel1").then(function (response) {
    
          dispatch({type:"FETCH_USERS_ADMIN_SUCCESS",payload:response.data})
        })
    
      }
      }
    
    export function getS3LogFileNames(userId) {
      console.log("in getS3LogFileNames="+userId)
      return (dispatch) => {
        dispatch({ type: "FETCH_S3LOGFILENAMES" })
        axios.post("/api/admin/getS3LogFileNames",{userId}).then(function (response) {
    
          dispatch({type:"FETCH_S3LOGFILENAMES_SUCCESS",payload:response.data})
        })
    
      }
      }
    
      export function downloadLogFile(userID,item) {
        console.log("in downloadLogFile="+userID+","+item)
        return (dispatch) => {
          //dispatch({ type: "FETCH_S3LOGFILENAMES" })
          axios.post("/api/downloadLogFile",{userID,item},{responseType:'blob'}).then(function (response) {
            console.log("response.data="+response.data)
          saveAs(response.data,item)
           // dispatch({type:"FETCH_S3LOGFILENAMES_SUCCESS",payload:response.data})
          })
      
        }
        }
export function savePlaylistName(playlistDetails) {
  return (dispatch) => {
    dispatch({ type: "SAVE_PLAYLIST_NAME" })
    axios.post("/api/savePlaylistName",{
      playlistName:playlistDetails.playlistName,
      playlistType: playlistDetails.playlistType}).then(function (response) {

      dispatch({type:"SAVE_PLAYLIST_NAME_SUCCESS",payload:response.data})
    })

  }
  }  

  export function savePlaylist(socialMediaType,playlistName,playlist) {
    return (dispatch) => {
      dispatch({ type: "UPDATE_PLAYLIST" })
      axios.post("/api/savePlaylist",{
        playlistName, playlist}).then(function (response) {
          console.log("response.data="
          +response.data);
          if(socialMediaType=="TWITTER")
            dispatch({type:"UPDATE_TWITTER_PLAYLIST_SUCCESS",payload:response.data})
          else
            dispatch({type:"UPDATE_INSTAGRAM_PLAYLIST_SUCCESS",payload:response.data})
      })
  
    }
  }  
  export function clearTwitterInstaPlaylist() {
    return (dispatch) => {
      dispatch({ type: "CLEAR_TWITTERINTA_PLAYLIST" })
    };
  }

  

  export function deletePlaylist(playlistId) {
    return (dispatch) => {
      dispatch({ type: "DELETE_PLAYLIST" });
      axios.post("/api/deletePlaylist", { playlistId }).then(function (response) {
        //dispatch({ type: "DELETE_PLAYLIST_SUCCESS", payload: response.data });
        var loggedInUserRole = JSON.parse(localStorage.getItem("userObj")).role;
      var manager_id = JSON.parse(localStorage.getItem("userObj")).manager_id;
      var arrTemp = [];
      if(loggedInUserRole=="001" || loggedInUserRole=="002"){
        axios.post("/api/admin/getLoggedInUserSubordinates",{loggedInUserRole}).then(function (response) {
          var subordinates = response.data;        
          subordinates.forEach((obj)=>{
            arrTemp.push(obj._id);
          });
          //for 002 user, should see playlists of level 1 and level 3
          (loggedInUserRole=="002")?arrTemp.push(manager_id):null;
          console.log("arrTemp="+arrTemp)
          axios.post("/api/getPlaylists",{loggedInUserRole, arrTemp}).then(function (response) {
  
        dispatch({type:"GET_PLAYLISTS_SUCCESS",payload:response.data})
      })
        });
      } else{
      axios.post("/api/getPlaylists",{loggedInUserRole,arrTemp:[manager_id]}).then(function (response) {
  
        dispatch({type:"GET_PLAYLISTS_SUCCESS",payload:response.data})
      })
    }
      });
    };
  }
  export function clonePlaylist(playlist) {
    return (dispatch) => {
      dispatch({ type: "SAVE_PLAYLIST_NAME" })
      axios.post("/api/clonePlaylist",{playlist}).then(function (response) {
  
        dispatch({type:"SAVE_PLAYLIST_NAME_SUCCESS",payload:response.data})
      })
  
    }
    }    
  export function getPlaylists() {
    return (dispatch) => {
      dispatch({ type: "GET_PLAYLISTS" })
      var loggedInUserRole = JSON.parse(localStorage.getItem("userObj")).role;
      var manager_id = JSON.parse(localStorage.getItem("userObj")).manager_id;
      var arrTemp = [];
      if(loggedInUserRole=="001" || loggedInUserRole=="002"){
        axios.post("/api/admin/getLoggedInUserSubordinates",{loggedInUserRole}).then(function (response) {
          var subordinates = response.data;        
          subordinates.forEach((obj)=>{
            arrTemp.push(obj._id);
          });
          //for 002 user, should see playlists of level 1 and level 3
          (loggedInUserRole=="002")?arrTemp.push(manager_id):null;
          console.log("arrTemp="+arrTemp)
          axios.post("/api/getPlaylists",{loggedInUserRole, arrTemp}).then(function (response) {
  
          dispatch({type:"GET_PLAYLISTS_SUCCESS",payload:response.data})
        })
        });
      } else{
      axios.post("/api/getPlaylists",{loggedInUserRole,arrTemp:[manager_id]}).then(function (response) {
  
        dispatch({type:"GET_PLAYLISTS_SUCCESS",payload:response.data})
      })
    }
      // axios.get("/api/getPlaylists").then(function (response) {
  
      //   dispatch({type:"GET_PLAYLISTS_SUCCESS",payload:response.data})
      // })
  
    }
    }   
    
    export function updatePlayList(playlist) {
      console.log("Updating playlist!")
      return (dispatch) => {
        dispatch({ type: "UPDATE_PLAYLIST" })
        axios.post("/api/updatePlaylist",{
          playlist}).then(function (response) {
            //console.log("***response.data="+JSON.stringify(response.data))
          dispatch({type:"UPDATE_PLAYLIST_SUCCESS",payload:response.data})
        })
      }
    }

export function generateGraph(screensData,graphColumns,fileName,selectedDateRange) {
          return (dispatch) => {
            dispatch({ type: "GENERATE_GRAPH" })
            axios.post("/api/generateGraph",{
              screensData,graphColumns,fileName,selectedDateRange}).then(function (response) {
                console.log("response.data"+response.data);
                var formattedObj = response.data;
                formattedObj.uploadDate = moment(formattedObj.uploadDate).format("DD-MM-YYYY")
            dispatch({type:"GENERATE_GRAPH_SUCCESS",payload:formattedObj})
        })
          }
          }        
          
          export function getSalesUploadData() {
            return (dispatch) => {
              dispatch({ type: "GET_SALES_UPLOAD_DATA" })
              axios.get("/api/getSalesUploadData").then(function (response) {
              dispatch({type:"GET_SALES_UPLOAD_DATA_SUCCESS",payload:response.data})
          })
  }
}
	
export function getExcludeAdsFile() {
  return (dispatch) => {
    dispatch({ type: "GET_EXCLUDE_ADS_FILE" })
    axios.get("/api/getExcludeAdsFile").then(function (response) {
      console.log("response from exclude ads="+response);
      dispatch({type:"GET_EXCLUDE_ADS_FILE_SUCCESS",payload:response.data})
    })

  }
  }                      

export function getManagerForUser(id){
    return (dispatch)=>{
      //dispatch({type:"FETCH_ADSLIST"})
      console.log("003 id="+id)
      axios.post("/api/admin/getManagerForUser",{id}).then(function (response){
        console.log("****response.data="+response.data)
          dispatch({type:"GET_MANAGER_FOR_USER_SUCCESS",payload:response.data})
        })
  
    }
  }

  export function getUsersForAccessPermissions(){
    var loggedInUserRole = JSON.parse(localStorage.getItem("userObj")).role;
    var loggedInUserPermissions = JSON.parse(localStorage.getItem("userObj")).permissions;
    return (dispatch)=>{
      //dispatch({type:"FETCH_ADSLIST"})
      axios.post("/api/admin/getUsersForAccessPermissions",{loggedInUserRole,loggedInUserPermissions}).then(function (response){
  
          dispatch({type:"GET_USERS_FOR_ACCESS",payload:response.data})
        })
  
    }
  }

  export function updatePermissions(id,accessId){
    console.log("updatePermissions="+id)
    console.log("updatePermissions="+accessId)
    return (dispatch)=>{
      //dispatch({type:"FETCH_ADSLIST"})
      axios.post("/api/admin/updatePermissions",{id,accessId}).then(function (response){
        console.log("here")
          dispatch({type:"UPDATE_PERMISSIONS",payload:response.data})
        })
  
    }
  }
  
  export function fetchUsersForSWVersion() {
    return (dispatch) => {
      dispatch({ type: "FETCH_USERS_ADMIN" })
      axios.post("/api/admin/getUsersForSWVersion").then(function (response) {
  
        dispatch({type:"FETCH_USERS_ADMIN_SUCCESS",payload:response.data})
      })
  
    }
    }

  export function fetchRoles(loggedInUserRole,manager_id) {
    return (dispatch) => {
      dispatch({ type: "FETCH_ROLES" })
      var arrTemp = [];
      if(loggedInUserRole=="001"){
        axios.post("/api/admin/getLoggedInUserSubordinates",{loggedInUserRole}).then(function (response) {
          var subordinates = response.data;        
          subordinates.forEach((obj)=>{
            arrTemp.push(obj._id);
          });
          
          console.log("arrTemp="+arrTemp)
          axios.post("/api/admin/getRoles",{loggedInUserRole,arrTemp}).then(function (response) {
            dispatch({type:"FETCH_ROLES_SUCCESS",payload:response.data})
          })
        });
      } else{
        axios.post("/api/admin/getRoles",{loggedInUserRole,arrTemp:[manager_id]}).then(function (response) {
          dispatch({type:"FETCH_ROLES_SUCCESS",payload:response.data})
        })
      
    }
      //axios.post("/api/admin/getRoles",{loggedInUserRole,manager_id}).then(function (response) {
    //   dispatch({type:"FETCH_ROLES_SUCCESS",payload:response.data})
    // })

    }
  }

  export function addRole(roleObj){
    return (dispatch)=>{
      dispatch({ type: "ADD_ROLE" })
      axios.post(`/api/admin/addRole`, {
        roleName : roleObj.roleName,
        description:roleObj.description,
        type:roleObj.type
    }
    ).then(response => {
      console.log("success!")
      dispatch({ type: "ADD_ROLE_SUCCESS",payload:{
          data:response.data
        } })
      
    }).catch(error => {
      console.log("failure!"+error)
      dispatch({ type: "ADD_ROLE_FAILURE" })
    });
  }
  }     
  
  export function updateRole(roleObj){
    return (dispatch)=>{
      dispatch({ type: "UPDATE_ROLE" })
      axios.post(`/api/admin/updateRole`, {
        roleName : roleObj.roleName,
        description:roleObj.description,
        type:roleObj.type,
        roleNameOriginal:roleObj.roleNameOriginal
    }
    ).then(response => {
      console.log("success!")
      dispatch({ type: "UPDATE_ROLE_SUCCESS",payload:{
          data:response.data
        },roleNameOriginal: roleObj.roleNameOriginal})
      
    }).catch(error => {
      console.log("failure!"+error)
      dispatch({ type: "UPDATE_ROLE_FAILURE" })
    });
  }
  }   

  export function updatePermissionsForRole(roleName,permissions){
    console.log("updatePermissionsForRole="+roleName)
    console.log("updatePermissionsForRole="+permissions)
    return (dispatch)=>{
      //dispatch({type:"FETCH_ADSLIST"})
      axios.post("/api/admin/updatePermissionsForRole",{roleName,permissions}).then(function (response){
        console.log("here"+JSON.stringify(response.data))
          dispatch({type:"UPDATE_PERMISSIONS_FOR_ROLE",payload:response.data})
        })
  
    }
  }

  export function saveDesignName(designDetails) {
    return (dispatch) => {
      dispatch({ type: "SAVE_DESIGN_NAME" })
      axios.post("/api/saveDesignName",{
        designName:designDetails.designName}).then(function (response) {
  
        dispatch({type:"SAVE_DESIGN_NAME_SUCCESS",payload:response.data})
      })
  
    }
    }  

    export function getDesigns() {
      return (dispatch) => {
        dispatch({ type: "GET_DESIGNS" })
        var loggedInUserRole = JSON.parse(localStorage.getItem("userObj")).role;
        var manager_id = JSON.parse(localStorage.getItem("userObj")).manager_id;
        var arrTemp = [];
        if(loggedInUserRole=="001" || loggedInUserRole=="002"){
          axios.post("/api/admin/getLoggedInUserSubordinates",{loggedInUserRole}).then(function (response) {
            var subordinates = response.data;        
            subordinates.forEach((obj)=>{
              arrTemp.push(obj._id);
            });
            //for 002 user, should see playlists of level 1 and level 3
            (loggedInUserRole=="002")?arrTemp.push(manager_id):null;
            console.log("arrTemp="+arrTemp)
            axios.post("/api/getDesigns",{loggedInUserRole, arrTemp}).then(function (response) {
    
          dispatch({type:"GET_DESIGNS_SUCCESS",payload:response.data})
        })
          });
        } else{
        axios.post("/api/getDesigns",{loggedInUserRole,arrTemp:[manager_id]}).then(function (response) {
    
          dispatch({type:"GET_DESIGNS_SUCCESS",payload:response.data})
        })
      }
        // axios.get("/api/getPlaylists").then(function (response) {
    
        //   dispatch({type:"GET_PLAYLISTS_SUCCESS",payload:response.data})
        // })
    
      }
      }       

      export function savePageToDesign(designDetails,type){
        return (dispatch) => {
          dispatch({ type: "UPDATE_DESIGN" })
          axios.post("/api/updateDesign",{designDetails,type}).then(function (response) {      
            dispatch({type:"UPDATE_DESIGN_SUCCESS",payload:response.data})
          })
      
        }
      }

      export function deleteDesign(designName) {
        return (dispatch) => {
          dispatch({ type: "DELETE_DESIGN" })
          axios.post("/api/deleteDesign",{designName}).then(function (response) {
      
            dispatch({type:"DELETE_DESIGN_SUCCESS",payload:response.data})
          })
      
        }
        }  
      export function cloneDesign(design) {
        return (dispatch) => {
          dispatch({ type: "SAVE_DESIGN_NAME" })
          axios.post("/api/cloneDesign",{design}).then(function (response) {
      
            dispatch({type:"SAVE_DESIGN_NAME_SUCCESS",payload:response.data})
          })
      
        }
      }   
      
      export function saveDesign(designObj){
        return (dispatch) => {
          dispatch({ type: "UPDATE_DESIGN" })
          axios.post("/api/saveDesign",{designObj}).then(function (response) {      
            console.log("got response!")
            dispatch({type:"UPDATE_DESIGN_SUCCESS",payload:{data:response.data, type:designObj.type}})
          })
      
        }
      } 

      export function getLatestAppVersion(){
        return (dispatch) => {
          dispatch({ type: "GET_APPVERSION" })
          axios.get("/api/getAppAPKVersion").then(function (response) {      
            console.log("got response!"+JSON.stringify(response.data))
            dispatch({type:"GET_APPVERSION_SUCCESS",payload:response.data})
          })
      
        }
      } 
      
      export function saveNewAppVersion(version){
        return (dispatch) => {
          axios.post("/api/saveNewAppVersion",{version}).then(function (response) {      
            console.log("got response!")
            dispatch({type:"GET_APPVERSION_SUCCESS",payload:response.data})
          })
      
        }
      } 

      export function getMonitorAPKVersion(){
        return (dispatch) => {
          dispatch({ type: "GET_MONITORVERSION" })
          axios.get("/api/getMonitorAPKVersion").then(function (response) {      
            console.log("got response!"+JSON.stringify(response.data))
            dispatch({type:"GET_MONITORVERSION_SUCCESS",payload:response.data})
          })
      
        }
      } 
      
      export function saveNewMonitorAPKVersion(version){
        return (dispatch) => {
          axios.post("/api/saveNewMonitorAPKVersion",{version}).then(function (response) {      
            console.log("got response!")
            dispatch({type:"GET_MONITORVERSION_SUCCESS",payload:response.data})
          })
      
        }
      } 

      export function updatePagesVisited(userID,pagesVisitedObj){
        return (dispatch) => {
          dispatch({ type: "UPDATE_PAGESVISITED" })
          axios.post("/api/updatePagesVisited",{userID,pagesVisitedObj}).then(function (response) {      
            console.log("got response!"+JSON.stringify(response.data))
            if(response.data.userObject!==null){
              localStorage.setItem("userObj",JSON.stringify(response.data.userObject))
            }            
            dispatch({type:"UPDATE_PAGESVISITED_SUCCESS"})
          })
      
        }
      }
export function fetchCurrencies(id){
        return (dispatch)=>{
          //dispatch({type:"FETCH_ADSLIST"})
          axios.get("/api/admin/fetchCurrencies").then(function (response){
      
              dispatch({type:"FETCH_CURRENCIES",payload:response.data})
            })
      
        }
      }   
export function getSubscriptionRatesForCurrency(currency){
            console.log("***currency"+currency)
            return (dispatch)=>{
              //dispatch({type:"FETCH_ADSLIST"})
              axios.post("/api/getSubscriptionRatesForCurrency",{currency}).then(function (response){
          
                  dispatch({type:"FETCH_CURRENCIES_CUSTOMER",payload:response.data})
                })
          
            }
          }    
export function saveDesignLayout(designObj,viewPortrait){
          console.log("viewPortrait in saveDesignLayout="+viewPortrait)
          return (dispatch) => {
            dispatch({ type: "SAVE_DESIGN_LAYOUT" })
            axios.post("/api/saveDesignLayout",{designObj}).then(function (response) {      
              console.log("got response!");
              console.log("response.data="+JSON.stringify(response.data));
              dispatch({type:"SAVE_DESIGN_LAYOUT_SUCCESS",payload:{data:response.data,viewPortrait}})
            })
        
          }
        }

        export function updateDesignLayout(designObj,viewPortrait){
          console.log("viewPortrait in action="+viewPortrait)
          return (dispatch) => {
            dispatch({ type: "SAVE_DESIGN_LAYOUT" })
            axios.post("/api/updateDesignLayout",{designObj}).then(function (response) {      
              console.log("got response!");
              console.log("response.data="+JSON.stringify(response.data));
              dispatch({type:"UPDATE_DESIGN_LAYOUT_SUCCESS",payload:{data:response.data,viewPortrait}})
            })
        
          }
        }

        export function changeLayoutName(previousName,newName,viewPortrait){
          console.log("***in changelayout="+previousName,newName)
          return (dispatch) => {
            dispatch({ type: "SAVE_DESIGN_LAYOUT" })
            axios.post("/api/changeLayoutName",{previousName,newName}).then(function (response) {      
              console.log("got response!");
              console.log("response.data="+JSON.stringify(response.data));
              dispatch({type:"CHANGE_DESIGN_LAYOUT_NAME_SUCCESS",payload:{data:response.data,viewPortrait}})
            })
        
          }
        }

        export function deleteSelectedLayout(designName,userID,viewPortrait){
          return (dispatch) => {
            dispatch({ type: "SAVE_DESIGN_LAYOUT" })
            axios.post("/api/deleteSelectedLayout",{designName,userID}).then(function (response) {      
              console.log("got response!");
              console.log("response.data="+JSON.stringify(response.data));
              dispatch({type:"DELETE_SELECTED_LAYOUT_SUCCESS",payload:{data:response.data,viewPortrait}})
            })
        
          }
        }
  
        export function getDesignLayouts(owner,selectedDesignId) {
          return (dispatch) => {
            dispatch({ type: "GET_DESIGNS" })            
            axios.post("/api/getDesignLayouts",{owner}).then(function (response) {
        
              dispatch({type:"GET_DESIGNS_SUCCESS",payload:{data:response.data,selectedDesignId}})
            })
          }
          }
export function fetchMyPlan(userID) {
        return (dispatch) => {
          //dispatch({ type: "FETCH_DEVICEIPS_ADMIN" })
          axios.post("/api/getMyPlan",{userID}).then(function (response) {
      
            dispatch({type:"FETCH_MY_PLAN",payload:response.data})
          })
      
        }
        }
  
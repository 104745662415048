import React from "React";
import {  Trans } from 'react-i18next';
import {connect} from "react-redux";
@connect((store)=>{
  return {
    
  }
})

export default class Footer extends React.Component{

    constructor(props){
      super(props);
    }

    render(){
    
        return (
            <nav class="navbar no-icon-nav navbar-expand-lg navbar-dark bd-navbar">
                <div style={{position: "fixed",left: "0",bottom: "0", width: "100%", backgroundColor: "skin-blue", color: "#4fbfbd", textAlign: "right", fontSize:"12px"}}>
    <a href="https://www.intelisa.in/privacy-policy/" target="_blank" style={{ color:"#4fbfbd"}}><Trans i18nKey="footer.privacypolicy"/></a>
  </div>
            </nav>
        )
    }
}
import React from "react";
import {registerUser,login} from "../../redux/actions/authAction.js"
import {connect} from "react-redux";
import Input from "../formcomponent/Input"
import { Redirect } from 'react-router-dom'
import Login_Form from "./Login_Form.js";
import i18n from '../../i18n';
import {  Trans } from 'react-i18next';


@connect((store)=>{
  return {
    isAuthenticated:store.auth.isAuthenticated
  }
})
export default class Verification_Form extends React.Component {
  constructor(props){
  super(props);
  this.state={
   
  
  }
  }

  registerUser = () =>{
    this.props.setComponentId('L_1');
  }

  handleChange=(e)=>{
    // console.log("Change Language!!!!")
    if(e.target.value == "English"){
      //set "EN"
      // console.log("in handle lang="+(e.target.value))
      localStorage.setItem("locale","en");
      i18n.changeLanguage("en");  
    
      this.setState({lang:"en"})
    }
    else if(e.target.value == "Francais"){
      //set "FR"
      // console.log("in handle lang="+(e.target.value))
      localStorage.setItem("locale","fr");
      i18n.changeLanguage("fr");  
      
      this.setState({lang:"fr"})
    }
    
  }

  render() {

    return (
      <div>
        
        <div style={{height:"100vh",width:"100vw",display:"flex",flexDirection:"row",overflowY:"auto",overflowX:"auto",background:"white",fontFamily:"Inter",columnCount:"2",padding: "0"}}>

        <div class="d-flex align-items-center justify-content-left" style={{width:"47%", position: 'relative'}}>
            <img   src="images/background.png" height="100%" width="100%"  alt="Card image cap" />
            <div style={{position: 'absolute', top: "40%", left: "32%", pointerEvents: 'none'}}>
              <img   src="images/Logo.svg" alt="SVG Overlay" height="100%"  width="100%"/>
            </div>
          </div>
          <div class="login-form justify-content-center" style={{height:"100%",width:"50%",background:"transparent",display:"flex",flexDirection:"column",padding: "0"}}>
            
            <div style={{height:"10%",width:"100%",textAlign:"right",color:"black",fontFamily:"Inter"}}>
            <br/><Trans i18nKey="login.selectlang"/>&nbsp;&nbsp;
              <select 
                value={this.state.selectValue} 
                onChange={this.handleChange} 
              >
              <option value="English">English</option>
                <option value="Francais">Fran�ais</option>
               
              </select>
               
            </div>

            <div class="d-flex flex-column justify-content-center" style={{paddingLeft:"30%",height:"80%"}}  >
              <img   src="images/EmailVerification.png" height="40%" width="45%"/>

              <div style={{width:"80%",color:"black",fontFamily:"Inter",fontStyle:"Bold",textAlign:"center",paddingRight:"20%"}}>
               <b>Verify your Email by clicking on the link we have just sent to your inbox.</b>
               </div>
            </div>

            {/* <div class="login-form d-flex no-shadow" style={{height:"10%",width:"100%",textAlign:"center",color:"black",fontWeight: "bold",fontFamily:"Inter",paddingLeft:"20%"}}>
              <h2>Verification</h2>
            </div> */}

            <div class="card-body d-flex flex-column" style={{width:"80%",paddingLeft:"20%"}}>
              

              <div style={{textAlign:"center"}}>
                    <a style={{border:"0px", textDecoration:"underline",color:"gray",background:"transparent",width:"100%",cursor:"pointer",fontFamily:"Inter"}} onClick={()=>this.registerUser()}>Back to Login</a>
                    {/* {this.state.registerRequest==true?< Login_Form />:null} */}
                   
              </div> 

              <div class="error-msg" style={{height:"40px"}}>
                {/* {!this.props.initialState && !this.props.isAuthenticated?<p style={{color:"#B22222"}}><Trans i18nKey="login.loginfailed"/></p>:null}
                {this.props.sendingResetEmail?<ComponentLoader/>:null}
                {this.props.sentResetEmail?<p style={{color:"#4fbfbd"}}><Trans i18nKey="login.resetmessage"/></p>:null}
                {this.props.resetEmailError?<p style={{color:"#B22222"}}><Trans i18nKey="login.validusernamemsg"/></p>:null} */}
              </div>                      

              <div style={{width:"100%", display:"flex",flexDirection:"row",minHeight:"10%"}}>
                    <div style={{width:"50%",textAlign:"left"}}><a href="https://www.intelisa.in/" target="_blank" style={{ color:"gray",fontFamily:"Inter"}}><Trans i18nKey="login.aboutus"/></a></div>
                    <div style={{width:"50%",textAlign:"right"}}><a href="https://www.intelisa.in/#get-free-trial" target="_blank" style={{ color:"gray",fontFamily:"Inter"}}><Trans i18nKey="login.contactus"/></a></div>
              </div>

            </div>
        </div>
      </div>
      </div>
    );
  }
}

import React,{Component} from "react";
import "./Checkbox.css";
 const Checkbox = (props) => {
  return (
<div className={props.rounded?`mc-checkbox rounded`:`mc-checkbox`} >
<input class="mc-checkbox-inp" id={props.id} checked={props.checked} disabled={props.disabled} type="checkbox" onChange={(e)=>props.handler(e)}/>
<label class="mc-checkbox-label" for={props.id}><span>
    <svg width="12px" height="10px" viewbox="0 0 12 10" >
      <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
    </svg></span><span>{props.label}</span></label>
</div>
  )
}
export default Checkbox;

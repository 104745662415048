import axios from "../../utils/axiosInstance";
import moment from "moment";
import momentTZ from 'moment-timezone';
export function fetchAdsList(){
 // return (dispatch)=>{
  //   dispatch({type:"FETCH_ADSLIST"})
  //   var loggedInUserRole = JSON.parse(localStorage.getItem("userObj")).role;
  //   axios.post("/api/getAdsList",{loggedInUserRole}).then(function (response){

  //       dispatch({type:"FETCH_ADSLIST_SUCCESS",payload:response.data})
  //     })

  // }
  return (dispatch)=>{
    dispatch({ type: "FETCH_ADSLIST" });
    var loggedInUserRole = JSON.parse(localStorage.getItem("userObj")).role;
    var manager_id = JSON.parse(localStorage.getItem("userObj")).manager_id;
    var arrTemp = [];
    if(loggedInUserRole=="001" || loggedInUserRole=="002"){
      axios.post("/api/admin/getLoggedInUserSubordinates",{loggedInUserRole}).then(function (response) {
        var subordinates = response.data;        
        subordinates.forEach((obj)=>{
          arrTemp.push(obj._id);
        });
        //for 002 user, should see ads and adgroups of level 1 and level 3
        (loggedInUserRole=="002")?arrTemp.push(manager_id):null;
        console.log("arrTemp="+arrTemp)
        axios.post("/api/getAdsList",{loggedInUserRole, arrTemp}).then(function (response) {

        dispatch({type:"FETCH_ADSLIST_SUCCESS",payload:response.data})
      })
      });
    } else{
    axios.post("/api/getAdsList",{loggedInUserRole,arrTemp:[manager_id]}).then(function (response) {

      dispatch({type:"FETCH_ADSLIST_SUCCESS",payload:response.data})
    })
  }

  }
}

export function fetchMyScreens() {
  return (dispatch) => {
    dispatch({ type: "FETCH_SCREENS_DATA" })
    var loggedInUserRole = JSON.parse(localStorage.getItem("userObj")).role;
    var arrTemp = [];
    if(loggedInUserRole=="001"){
      axios.post("/api/admin/getLoggedInUserSubordinates",{loggedInUserRole}).then(function (response) {
        var subordinates = response.data;        
        subordinates.forEach((obj)=>{
          arrTemp.push(obj._id);
        });
        axios.post("/api/admin/getScreens",{arrTemp}).then(function (response) {

        let tempArr=response.data.map((screen)=>{
        let temObj= Object.assign({},screen,{
            //"tags": ["L0_A01"],
              //"tags":[screen.screenType],
            "screenGroup":"group1",
            "status":1,

            "type": "type1",
            "utilization":10,
            "currentlyPlaying":"HCL",
            "lastData":"01-02-2018",
            "revenueMonth":"10L",
            "isSelected":false
          })
          return temObj;
        })
      dispatch({type:"FETCH_SCREENS_DATA_SUCCESS",payload:tempArr})
    })
      });
    }else{
    axios.post("/api/admin/getScreens",{arrTemp}).then(function (response) {

        let tempArr=response.data.map((screen)=>{
        let temObj= Object.assign({},screen,{
            //"tags": ["L0_A01"],
            //"tags":[screen.screenType],
            "screenGroup":"group1",
            "status":1,

            "type": "type1",
            "utilization":10,
            "currentlyPlaying":"HCL",
            "lastData":"01-02-2018",
            "revenueMonth":"10L",
            "isSelected":false
          })
          return temObj;
        })
      dispatch({type:"FETCH_SCREENS_DATA_SUCCESS",payload:tempArr})
    })
  }

  }
  }

  // [{
  //   "id": 1,
  //   "screenName": "Screen 1",
  //   "adName": "Amazon",
  //   "adGroup": "Amazon",
  //   "liveRunningTime": "2:43",
  //   "totalTime": "2:43",
  //   "completed":true
  // },{
  //   "id": 2,
  //   "screenName": "Screen 2",
  //   "adName": "KitKat",
  //   "adGroup": "Nestle",
  //   "liveRunningTime": "2:43",
  //   "totalTime": "2:43",
  //   "completed":true
  // }, {
  //   "id": 3,
  //   "screenName": "Screen 2",
  //   "adName": "Rin",
  //   "adGroup": "HUL",
  //   "liveRunningTime": "2:43",
  //   "totalTime": "2:43",
  //   "completed":true
  // }, {
  //   "id": 4,
  //   "screenName": "Screen 2",
  //   "adName": "XUV 500",
  //   "adGroup": "M&M",
  //   "liveRunningTime": "2:43",
  //   "totalTime": "2:43",
  //   "completed":true
  // }, {
  //   "id": 5,
  //   "screenName": "Screen 3",
  //   "adName": "Nirma",
  //   "adGroup": "HUL,",
  //   "liveRunningTime": "0:05",
  //   "totalTime": "2:43",
  //   "completed":false
  // }]

export function fetchScheduleList(screenId,imageRotation) {
  //clone to IST Date
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  var todayDate = momentTZ.tz(moment().format("YYYY-MM-DD"), timeZone).clone().tz("Asia/Kolkata").format("YYYY-MM-DD");
  console.log("***todayDate = "+momentTZ.tz(moment().format("YYYY-MM-DD"), timeZone).clone().tz("Asia/Kolkata").format("YYYY-MM-DD"))
  return (dispatch) => {
    dispatch({ type: "FETCH_SCHEDULE_LIST" })

    //axios.post("/api/fetchScheduleMetadata", { screenId: screenId }).then(function (response) {
      axios.post("/api/fetchScheduleList", { screenId: screenId, imageRotation,todayDate }).then(function (response) {
      dispatch({
        type: "FETCH_SCHEDULE_LIST_SUCCESS", payload: response.data
      })
      console.log("in fetchschedulelist")
    })

  }
}	

export function fetchScheduleDataForDate(screenId, startDate, imageRotation) {
  return (dispatch) => {
    console.log("in fetchScheduleDataForDate")
    dispatch({ type: "FETCH_SCHEDULE_LIST" })

    axios.post("/api/fetchScheduleDataForDate", { screenId: screenId, startDate:startDate, imageRotation }).then(function (response) {
      dispatch({
        type: "FETCH_SCHEDULE_LIST_SUCCESS", payload: response.data
      })
      console.log("in fetchScheduleDataForDate")
    })

  }
}

export function fetchAdDownloadStatus(screenId) {
  return (dispatch) => {
    dispatch({ type: "FETCH_AD_DOWNLOAD_STATUS" })
    axios.post("/api/fetchAdDownloadStatus", { screenId: screenId }).then(function (response) {
      dispatch({
        type: "FETCH_AD_DOWNLOAD_STATUS_SUCCESS", payload: response.data
      })
    })

  }
}

export function saveSchedule(screens,slots,adList,selectedScreenObjects,daysOfWeek){
  return (dispatch)=>{
dispatch({type:"SAVE_SCREEN_SCHEDULE"})
     axios.post("/api/saveSchedule",{selectedScreens:screens,selectedSlots:slots,selectedAds:adList,selectedScreenObjects,daysOfWeek}).then(function (response){
dispatch({type:"SAVE_SCREEN_SCHEDULE_SUCCESS"})

       })

  }
}

export function saveConfRoomSchedule(screens,slots,subject,iteration){
  return (dispatch)=>{
dispatch({type:"SAVE_SCREEN_SCHEDULE"})
     axios.post("/api/saveConfRoomSchedule",{role:JSON.parse(localStorage.getItem("userObj")).role,manager_id:JSON.parse(localStorage.getItem("userObj")).manager_id,
       selectedScreens:screens,selectedSlots:slots,subject:subject,userName:JSON.parse(localStorage.getItem("userObj")).userName,iteration}).then(function (response){
       console.log("response="+response.data.success)
       dispatch({type:"SAVE_CONF_SCREEN_SCHEDULE_SUCCESS"})
      //  if(response.data!==null && response.data.success=="success"){
      // axios.post("/api/saveMainScreenSchedule",{selectedSlots:slots}).then(function (response1){

//dispatch({type:"SAVE_SCREEN_SCHEDULE_SUCCESS"})
    //   })
    // }
       })

  }
}

export function saveMainScreenSchedule(slots){
  return (dispatch)=>{
dispatch({type:"SAVE_SCREEN_SCHEDULE"})
axios.post("/api/saveMainScreenSchedule",{role:JSON.parse(localStorage.getItem("userObj")).role,manager_id:JSON.parse(localStorage.getItem("userObj")).manager_id,
selectedSlots:slots}).then(function (response){
       dispatch({type:"SAVE_SCREEN_SCHEDULE_SUCCESS"})
      //  if(response.data!==null && response.data.success=="success"){
      // axios.post("/api/saveMainScreenSchedule",{selectedSlots:slots}).then(function (response1){

//dispatch({type:"SAVE_SCREEN_SCHEDULE_SUCCESS"})
    //   })
    // }
       })

  }
}


export function saveAdScreenStatus(screens,adList){
  return (dispatch)=>{
dispatch({type:"SAVE_AD_SCREEN_STATUS"})
     axios.post("/api/saveAdScreenStatus",{selectedScreens:screens,selectedAds:adList}).then(function (response){
dispatch({type:"SAVE_AD_SCREEN_STATUS_SUCCESS"})

       })

  }
}

export function fetchScreenOverview(screenId){
  return (dispatch)=>{

    dispatch({type:"FETCH_SCREEN_OVERVIEW"})
    axios.post("/api/fetchScreenOverview",{screenId:screenId}).then(function (response){
        console.log("**Screen overview response="+JSON.stringify(response.data))
        dispatch({type:"FETCH_SCREEN_OVERVIEW_SUCCESS",payload:response.data})
      })

  }
}
export function fetchDeviceDetails(device){
  return (dispatch)=>{

    dispatch({type:"FETCH_DEVICE_DET"})
    axios.post("/api/fetchDeviceAds",{deviceKey:device}).then(function (response){

        dispatch({type:"FETCH_DEVICE_DET_SUCCESS",payload:response.data})
      })

  }
}
export function removeAdFromSchedule(screenId,schedule, startDate){
  return (dispatch)=>{

    dispatch({type:"FETCH_SCHEDULE_LIST"})
    axios.post("/api/removeAdFromSchedule",{screenId:screenId,schedule:schedule, startDate:startDate}).then(function (response){

        dispatch({type:"FETCH_SCHEDULE_LIST_SUCCESS",payload:response.data})
        //to get schedules for all days
      axios.post("/api/fetchScheduleData", { screenId: screenId }).then(function (response) {
        dispatch({
          type: "FETCH_SCHEDULE_DATA_SUCCESS", payload: response.data
        })
      })
      })
      
  }
}

//trying
export function removeAdFromScheduleForDate(toDeleteData, screenId, startDate){
  return (dispatch)=>{
    console.log("toDeleteData-"+JSON.stringify(toDeleteData))
    //console.log("byte size="+new Blob(JSON.stringify(toDeleteData)).size)
    dispatch({type:"FETCH_SCHEDULE_LIST"})
    axios.post("/api/removeAdFromScheduleForDate",{toDeleteData:toDeleteData}).then(function (response){
      axios.post("/api/fetchScheduleDataForDate", { screenId: screenId, startDate:startDate }).then(function (response) {
        dispatch({
          type: "FETCH_SCHEDULE_LIST_SUCCESS", payload: response.data
        })
        //to get schedules for all days
      axios.post("/api/fetchScheduleData", { screenId: screenId,todayDate:moment(startDate).format("YYYY-MM-DD") }).then(function (response) {
        dispatch({
          type: "FETCH_SCHEDULE_DATA_SUCCESS", payload: response.data
        })
      })
      });
        // console.log("in remove="+response.data);
        // dispatch({type:"FETCH_SCHEDULE_DATA_SUCCESS",payload:response.data})
      })
      console.log("in remove ad from schedule")
  }
}


export function fetchSocialMedia(socialMediaInput){
  return (dispatch)=>{

    dispatch({type:"FETCH_SOCIAL_MEDIA"})
    var loggedInUserRole = JSON.parse(localStorage.getItem("userObj")).role;
    axios.post("/api/fetchSocialMedia",{loggedInUserRole}).then(function (response){
        dispatch({type:"FETCH_SOCIAL_MEDIA_SUCCESS",payload:response.data})
      })

  }
}

export function saveScreenSaver(screens, screenSaver){
  return (dispatch)=>{
dispatch({type:"SAVE_SCREEN_SAVER"})
     axios.post("/api/saveScreenSaver",{selectedScreens:screens,screenSaver}).then(function (response){
dispatch({type:"SAVE_SCREEN_SAVER_SUCCESS"})

       })

  }
}	 

export function saveLayout(screens, selectedLayout,restartFlag){
  return (dispatch)=>{
dispatch({type:"SAVE_LAYOUT"})
     axios.post("/api/saveLayout",{selectedScreens:screens,selectedLayout,restartFlag}).then(function (response){
dispatch({type:"SAVE_LAYOUT_SUCCESS"})

       })

  }
}
	 export function saveLoginDetails(selectedScreenId,userDetails){
  return (dispatch)=>{
dispatch({type:"SAVE_LOGIN_DETAILS"})
     axios.post("/api/saveLoginDetails",{selectedScreenId,userDetails}).then(function (response){
dispatch({type:"SAVE_LOGIN_DETAILS_SUCCESS"})

       })

  }
}

export function fetchImpressionsData(selectedScreenId){
  return (dispatch)=>{
dispatch({type:"FETCH_IMPRESSIONS_DATA"})
     axios.post("/api/fetchImpressionsData",{selectedScreenId}).then(function (response){
       console.log("impressions response="+response.data)
dispatch({type:"FETCH_IMPRESSIONS_DATA_SUCCESS",payload:response.data})

       })

  }
}

export function saveSyncGroup(selectedScreens, removedScreens,syncGroupIds,syncgroupname){
  return (dispatch)=>{
dispatch({type:"SAVE_SYNC_GROUP"})
     axios.post("/api/saveSyncGroup",{selectedScreens:selectedScreens,removedScreens:removedScreens,syncGroupIds:syncGroupIds,
    selectedSyncGroup:syncgroupname}).then(function (response){
dispatch({type:"SAVE_SYNC_GROUP_SUCCESS"})

       })

  }
}


export function saveScreenWidget(screens,widgets){
  return (dispatch)=>{
dispatch({type:"SAVE_SCREEN_WIDGET"})
     axios.post("/api/savescreenwidget",{selectedScreens:screens,selectedWidgets:widgets}).then(function (response){
dispatch({type:"SAVE_SCREEN_WIDGET_SUCCESS"})

       })

  }
}

export function saveAnimation(screens,animation){
  return (dispatch)=>{
dispatch({type:"SAVE_SCREEN_WIDGET"})
     axios.post("/api/saveAnimation",{selectedScreens:screens,animation}).then(function (response){
dispatch({type:"SAVE_SCREEN_WIDGET_SUCCESS"})

       })

  }
}

export function saveTouchscreenURL(screens,touchscreenURL,websiteURL){
  return (dispatch)=>{
dispatch({type:"SAVE_SCREEN_WIDGET"})
     axios.post("/api/saveTouchscreenURL",{selectedScreens:screens,touchscreenURL,websiteURL}).then(function (response){
dispatch({type:"SAVE_SCREEN_WIDGET_SUCCESS"})

       })

  }
}

export function saveDeleteAds(screens,selectedAds){
  return (dispatch)=>{
dispatch({type:"SAVE_DELETEADS"})
     axios.post("/api/saveDeleteAds",{selectedScreens:screens,selectedAds}).then(function (response){
      console.log("saved!!")
dispatch({type:"SAVE_DELETEADS_SUCCESS"})

       })

  }
}

export function fetchMyScreensForDeleteLicenses(userID) {
  console.log("fetchMyScreensForDeleteLicenses action "+userID)
  return (dispatch) => {
    dispatch({ type: "FETCH_SCREENS_DATA" })
    var loggedInUserRole = JSON.parse(localStorage.getItem("userObj")).role;
    var arrTemp = [];
      axios
      .post("/api/admin/getUsersInHierarchy", { userID }).then(function (response) {
        var arrTemp = response.data;
        console.log("****arrTemp in fetch screens="+JSON.stringify(arrTemp))
        axios.post("/api/admin/fetchMyScreensForDeleteLicenses",{arrTemp}).then(function (response) {
          console.log("fetch screens after getting response")
        
        dispatch({type:"FETCH_SCREENS_DATA_SUCCESS",payload:response.data})
        })
      });
    

  }
  }
 export function getLastUploadedAd(){
    return (dispatch)=>{
  dispatch({type:"CLEAR_LAST_UPLOADED_AD"})
       axios.get("/api/getLastUploadedAd").then(function (response){
  dispatch({type:"GET_LAST_UPLOADED_AD",payload:response.data})
  
         })
  
    }
  }

  
  export function getDeleteLicensesForCustomer(){
    return (dispatch)=>{
  //dispatch({type:"CLEAR_LAST_UPLOADED_AD"})
  var customerID = JSON.parse(localStorage.getItem("userObj")).userID;
       axios.post("/api/getDeleteLicensesForCustomer",{customerID}).then(function (response){
  dispatch({type:"GET_DELETED_LICENSES",payload:response.data})
  
         })
  
    }
  }

  export function checkFirstSteps(){
    return (dispatch)=>{
      var customerID = JSON.parse(localStorage.getItem("userObj")).userID;
      axios.post("/api/checkFirstSteps",{customerID}).then(function (response){
        dispatch({type:"CHECK_FIRST_STEPS",payload:response.data})        
      })
    }
  }
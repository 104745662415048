import React,{Component} from "react";
import "../../../css/uilib/componentloadersmall.css";

export default class ComponentLoaderSmall extends Component {
  render(){
    return(
      <div class="d-flex align-items-center justify-content-center componentloadersmall ag-overlay-loading-center" >

  <figure></figure>
  <figure></figure>
  <figure></figure>
  <figure></figure>
  <figure></figure>

      </div>
    )
  }
}

import React from "react";
import {connect} from "react-redux";
import Input from "../formcomponent/Input"
import i18n from '../../i18n';
import {  Trans } from 'react-i18next';
import axios from "../../utils/axiosInstance";
import ComponentLoaderSmall from "../uilib/ComponentLoaderSmall.js";

import "../../../css/formcomponent/input.css"

import {resetPassword} from "../../redux/actions/authAction.js"
import ActionButton from "../formcomponent/ActionButton";

@connect((store)=>{
  return {
    isAuthenticated:store.auth.isAuthenticated
  }
})
export default class Email_Form extends React.Component {
  constructor(props){
  super(props);
  this.state={
    emailId:"",
    userName:"",
    resetRequest:false,
    password:"",
    newPassword:"",
    confirmPassword:"",
    resetError:"",
    showPassword:false,
    showPassword1:false,
    showPassword2:false,
    passwordMismatch:false,
    mailIdNotVerified:false,
    passSuccess:false,
  }
  }

  componentDidMount(){

    // var langInBrowser = detectBrowserLanguage().substring(0,2);
    console.log("new password======"+this.state.newPassword)
    
    if(localStorage.getItem("locale") == "fr"){
      localStorage.setItem("locale","fr");
    i18n.changeLanguage("fr");  
  
    this.setState({lang:"fr"})
    }
    else{
      
      localStorage.setItem("locale","en");
      i18n.changeLanguage("en");  
  
      this.setState({lang:"en"})}
    
  }


  handleChange=(e)=>{
    // console.log("Change Language!!!!")
    if(e.target.value == "English"){
      //set "EN"
      // console.log("in handle lang="+(e.target.value))
      localStorage.setItem("locale","en");
      i18n.changeLanguage("en");  
    
      this.setState({lang:"en"})
    }
    else if(e.target.value == "Francais"){
      //set "FR"
      // console.log("in handle lang="+(e.target.value))
      localStorage.setItem("locale","fr");
      i18n.changeLanguage("fr");  
      
      this.setState({lang:"fr"})
    }
    
  }

  confirmEmail = () => {
    
    var userName = this.state.emailId
    this.setState({userName});
    var locale = localStorage.getItem("locale")
    this.setState({sendingResetEmail:true,mailIdNotVerified:false,resetError:false});
    
    axios.post(`/api/sendEmailForReset`,{userName,locale}).then((response)=>{
      // console.log("Resetting mail Response", response.status)
      this.state.resetRequest = true
      this.setState({resetRequest:true,sendingResetEmail:false})

    }).catch((err)=>{
      // console.log("err=======tttttttttttttttt======"+err.response.data.code)
      if(err.response.data.code == 100){
        console.log("Email id is not verified!!!!")
        this.setState({mailIdNotVerified: true,sendingResetEmail:false})  
      }
      else{
        this.state.resetRequest = false
        this.setState({resetError: true,sendingResetEmail:false})
      }
      
    })

    // console.log("err=======tttttttttttttttt======"+recRes)

    // if(this.state.emailId){ //check @
    //     this.props.dispatch(sendEmailForReset(this.state.emailId,localStorage.getItem("locale")));
    //   // this.state.resetRequest = true
    //   this.setState({resetRequest:true})
    //   console.log("Reset Form", this.state.resetRequest)
    //   // this.props.setComponentId('R_5'); //Reset Form
    // }else{
    //   console.log("Email Id is Mandatory")
    // }
    
  };


  backToLogin = () => {
    this.props.setComponentId('L_1'); //Reset Form
  };
  
  setFieldValue=({target})=>{
     this.setState({[target.name]:target.value})
  }
// -----------------------------Merging
// validatePWD = (value) => {
//   return this.state.newPassword==value
// };
passResetSuccess = () => {
  this.props.setComponentId('L_1');
}

resetPassword = () => {
  console.log("Reset UserName",this.state.emailId,this.state.password,this.state.newPassword,this.state.confirmPassword)
  this.setState({passwordMismatch:false})
  if((this.state.newPassword === this.state.confirmPassword) && (this.state.confirmPassword.length > 5)){
  this.props.dispatch(resetPassword(this.state))
  this.state.passSuccess = true
  setTimeout(() => {this.passResetSuccess()},4000)
  // this.props.setComponentId('L_1');
  
  // this.passResetSuccess()
  }else{
    this.setState({passwordMismatch:true})

  }
};

handleClickShowPassword = () => {
  // console.log("inhandle click show password", this.state.showPassword)
  var currShowPass = this.state.showPassword
  // console.log("value of click show password", currShowPass)
  this.setState({showPassword:!currShowPass})
};

eyeHandler = () => {
  var imglink = " ";
  if(this.state.showPassword){imglink = "images/eye_open.png"}
  else{imglink = "images/eye_close.png"}
  return imglink
};

handleClickShowPassword1 = () => {
  var currShowPass1 = this.state.showPassword1
  this.setState({showPassword1:!currShowPass1})
};

eyeHandler1 = () => {
  var imglink1 = " ";
  if(this.state.showPassword1){imglink1 = "images/eye_open.png"}
  else{imglink1 = "images/eye_close.png"}
  return imglink1
};

handleClickShowPassword2 = () => {
  var currShowPass2 = this.state.showPassword2
  this.setState({showPassword2:!currShowPass2})
};

eyeHandler2 = () => {
  var imglink2 = " ";
  if(this.state.showPassword2){imglink2 = "images/eye_open.png"}
  else{imglink2 = "images/eye_close.png"}
  return imglink2
};

// setFieldValue=({target})=>{

//    this.setState({[target.name]:target.value})

//  }

//  componentDidMount(){
//   if(localStorage.getItem("locale")==null){
//     console.log("setting locale");
//     var localeFromUser = JSON.parse(localStorage.getItem("userObj")).locale;
//     if(localeFromUser==null){
//       localeFromUser = "en";
//     }
//     console.log("localeFromUser="+localeFromUser);
//     localStorage.setItem("locale",localeFromUser);
//     i18n.changeLanguage(localeFromUser);
//   }
//  }


// -----------------------------Merging End



  render() {
    if(!this.state.resetRequest){
    return (
      <div >
        
        <div style={{height:"100vh",width:"100vw",display:"flex",flexDirection:"row",overflowY:"auto",overflowX:"auto",background:"white",fontFamily:"Inter",columnCount:"2"}}>
        
        <div class="d-flex align-items-center justify-content-left" style={{width:"47%", position: 'relative'}}>
            <img   src="images/background.png" height="100%" width="100%"  alt="Card image cap" />
            <div style={{position: 'absolute', top: "40%", left: "32%", pointerEvents: 'none'}}>
              <img   src="images/Logo.svg" alt="SVG Overlay" height="100%"  width="100%"/>
            </div>
          </div>

          <div class="login-form justify-content-center" style={{height:"100%",width:"50%",background:"transparent",display:"flex",flexDirection:"column",padding: "0"}}>
            
            <div style={{height:"10%",width:"100%",textAlign:"right",color:"black",fontFamily:"Inter"}}>
              <br/><Trans i18nKey="login.selectlang"/>&nbsp;&nbsp;
              <select 
                value={this.state.selectValue} 
                onChange={this.handleChange} 
              >
              <option value="English">English</option>
                <option value="Francais">Francais</option>
               
              </select>
               
            </div>

            <div class="login-form d-flex no-shadow" style={{height:"10%",width:"100%",textAlign:"center",color:"black",fontWeight: "bold",fontFamily:"Inter",paddingLeft:"18%"}}>
              <h2><Trans i18nKey="login.forgotpassword"/></h2>
            </div>
            <div class="login-form d-flex no-shadow" style={{height:"10%",width:"100%",textAlign:"center",color:"gray",fontWeight: "bold",fontFamily:"Inter",paddingLeft:"18%"}}>
             <h5 style={{backgroundColor:"white"}}><Trans i18nKey="forgotpassword.text"/></h5>
            </div>
              <div class="card-body d-flex flex-column " style={{width:"83%",paddingLeft:"18%"}}>
                <div style={{width:"100%",color:"black",fontFamily:"Inter"}}>
                <Trans i18nKey="forgotpassword.email"/>
                  <Input
                                text={i18n.t('register.emailid')}
                                ref="emailId"
                                name="emailId"
                                validator={true}
                                // theme={"black"}
                                value={this.state.emailId}
                                onChange={this.setFieldValue}
                                // onChange={()=>this.setFieldValue()}
                                mandatory

                                emptyMessage="Application name can't be empty"
                    />
                </div>
            
                    <button class="btn btn-outline-primary" onClick={()=>this.confirmEmail()} style={{width:"100%",color:"white",background:"#66CBC9",textAlign:"center",fontFamily:"Inter"}}><Trans i18nKey="forgotpassword.proceed"/></button>
                    
                
                <div style={{textAlign:"center",paddingTop:"5%"}}>
                    <a style={{border:"0px", textDecoration:"underline",color:"gray",background:"transparent",width:"100%",cursor:"pointer",fontFamily:"Inter"}} onClick={()=>this.backToLogin()}><Trans i18nKey="forgotpassword.backtologin"/></a>
                    {/* {this.state.registerRequest==true?< Login_Form />:null} */}
                   
                </div>

              <div class="error-msg" style={{height:"30%",paddingTop:"10%",textAlign:"center"}}>
                {/* {!this.props.initialState && !this.props.isAuthenticated?<p style={{color:"#B22222"}}><Trans i18nKey="login.loginfailed"/></p>:null}
                {this.props.sendingResetEmail?<ComponentLoader/>:null}
                {this.props.sentResetEmail?<p style={{color:"#4fbfbd"}}><Trans i18nKey="login.resetmessage"/></p>:null} */}
                {this.state.sendingResetEmail?<ComponentLoaderSmall/>:null}
                {this.state.mailIdNotVerified?<p style={{color:"#B22222"}}>Email Id is not verified, can not reset password</p>:null}
                {this.state.resetError?<p style={{color:"#B22222"}}><Trans i18nKey="login.validusernamemsg"/></p>:null}
              </div>                      

              <div style={{width:"100%", display:"flex",flexDirection:"row",minHeight:"10%",paddingTop:"20%"}}>
                    <div style={{width:"50%",textAlign:"left"}}><a href="https://www.intelisa.in/" target="_blank" style={{ color:"gray",fontFamily:"Inter"}}><Trans i18nKey="login.aboutus"/></a></div>
                    <div style={{width:"50%",textAlign:"right"}}><a href="https://www.intelisa.in/#get-free-trial" target="_blank" style={{ color:"gray",fontFamily:"Inter"}}><Trans i18nKey="login.contactus"/></a></div>
              </div>

            </div>
        </div>
      </div>
      </div>
    );
  } else {
    return (
      <div>
        
        <div style={{height:"100vh",width:"100vw",display:"flex",flexDirection:"row",overflowY:"auto",overflowX:"auto",background:"white",fontFamily:"Inter",columnCount:"2"}}>

        <div class="d-flex align-items-center justify-content-left" style={{width:"47%", position: 'relative'}}>
            <img   src="images/background.png" height="100%" width="100%"  alt="Card image cap" />
            <div style={{position: 'absolute', top: "40%", left: "32%", pointerEvents: 'none'}}>
              <img   src="images/Logo.svg" alt="SVG Overlay" height="100%"  width="100%"/>
            </div>
          </div>

        <div class="login-form justify-content-center" style={{height:"100%",width:"50%",background:"transparent",display:"flex",flexDirection:"column",padding: "0"}}>
          

          <div style={{height:"10%",width:"100%",textAlign:"right",color:"black",fontFamily:"Inter"}}>
              <br/><Trans i18nKey="login.selectlang"/>&nbsp;&nbsp;
              <select 
                value={this.state.selectValue} 
                onChange={this.handleChange} 
              >
              <option value="English">English</option>
                <option value="Francais">Francais</option>
               
              </select>

            </div>

            <div class="login-form d-flex no-shadow" style={{height:"10%",width:"100%",textAlign:"center",color:"black",fontWeight: "bold",fontFamily:"Inter",paddingTop:"40px",paddingLeft:"20%"}}>
              <h2><Trans i18nKey="login.reset"/></h2>
            </div>
            
            <div class="login-form d-flex no-shadow" style={{height:"10%",width:"100%",textAlign:"center",color:"gray",fontFamily:"Inter",paddingTop:"40px",paddingLeft:"20%"}}>
            <Trans i18nKey="reset.onetimepass"/>
            </div>

            <div class="card-body d-flex flex-column" style={{width:"83%",paddingLeft:"20%"}}>
                {/* <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",width:"100%",color:"gray",fontFamily:"Inter"}}> */}
                    <div class="input_group input_valid input_empty input_unfocused boxed" style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",width:"100%",color:"gray",fontFamily:"Inter",height:"13%"}}>
                          <input type={this.state.showPassword ? "text" : "password"} 
                          name="password" 
                          id="password" 
                          placeholder={i18n.t('reset.otp')} 
                          onChange={this.setFieldValue}
                          style={{width:"100%",color:"gray",fontFamily:"Inter",height:"100%",paddingLeft:"3%",marginBottom:"2%",border:"transparent",outline: "none",margin:"auto"}}
                          // style={{borderRadius:"10px",border:"1px solid #868e96"}}
                          // required

                          />
                          {/* <button style={{border:"0px",color:"gray",background:"transparent",cursor:"pointer",width:"10%",alignItems:"right"}} onClick={this.handleClickShowPassword}><img   src="images/eye.png" height="100%" width="100%" /></button> */}
                    </div>
                    {/* <div style={{width:"10%", height:"100%"}}><button style={{border:"0px",color:"gray",background:"transparent",cursor:"pointer"}} onClick={this.handleClickShowPassword}><img   src="images/eye.png" height="100%" width="100%" color="black" background="#66CBC9"/></button></div> */}
                {/* </div> */}
                {/* <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",width:"100%",color:"black",fontFamily:"Inter"}}> */}
                    <div class="input_group input_valid input_empty input_unfocused boxed" style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",width:"100%",color:"gray",fontFamily:"Inter",height:"13%"}}>
                          <input type={this.state.showPassword1 ? "text" : "password"}  
                          name="newPassword" 
                          id="newPassword" 
                          placeholder={i18n.t('reset.newpwd')} 
                          // minlength="6"
                          // maxlength="40"
                          onChange={this.setFieldValue}
                          style={{width:"100%",color:"gray",fontFamily:"Inter",height:"100%",paddingLeft:"3%",marginBottom:"2%",border:"transparent",outline: "none",margin:"auto"}}
                          // style={{borderRadius:"10px",border:"1px solid #868e96"}}
                          // required

                          />
                          <button style={{border:"0px",color:"gray",background:"transparent",cursor:"pointer",width:"10%",alignItems:"right"}} onClick={this.handleClickShowPassword1}><img   src={this.eyeHandler1()} height="100%" width="100%" /></button>
                    </div>
                    {/* <div style={{width:"10%", height:"100%"}}><button style={{border:"0px",color:"gray",background:"transparent",cursor:"pointer"}} onClick={this.handleClickShowPassword1}><img   src="images/eye.png" height="100%" width="100%" /></button></div> */}
                {/* </div> */}
                {/* <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",width:"100%",color:"black",fontFamily:"Inter"}}> */}
                    <div class="input_group input_valid input_empty input_unfocused boxed" style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",width:"100%",color:"gray",fontFamily:"Inter",height:"13%"}}>
                    <input type={this.state.showPassword2 ? "text" : "password"}  
                          name="confirmPassword" 
                          id="confirmPassword" 
                          placeholder={i18n.t('reset.confirmpwd')} 
                          onChange={this.setFieldValue}
                          style={{width:"100%",color:"gray",fontFamily:"Inter",height:"100%",paddingLeft:"3%",marginBottom:"2%",border:"transparent",outline: "none",margin:"auto"}}
                          // style={{borderRadius:"10px",border:"1px solid #868e96"}}
                          // required

                          />
                          <button style={{border:"0px",color:"gray",background:"transparent",cursor:"pointer",width:"10%",alignItems:"right"}} onClick={this.handleClickShowPassword2}><img   src={this.eyeHandler2()} height="100%" width="100%" /></button>
                    </div>
                    {/* <div style={{width:"10%", height:"100%"}}><button style={{border:"0px",color:"gray",background:"transparent",cursor:"pointer"}} onClick={this.handleClickShowPassword2}><img   src="images/eye.png" height="100%" width="100%" /></button></div> */}
                {/* </div> */}
            
            <div>
              <ActionButton containerClass={`${this.props.isResetting?"loading":null} btn btn-outline-theme`} clickHandler={()=>this.resetPassword()} style={{width:"100%",color:"white",background:"#66CBC9",textAlign:"center",fontFamily:"Inter"}}>{i18n.t('reset.reset')}</ActionButton>
               
            </div>
            <div class="error-msg" style={{height:"30%",paddingTop:"10%",textAlign:"center"}}>
                {/* {!this.props.initialState && !this.props.isAuthenticated?<p style={{color:"#B22222"}}><Trans i18nKey="login.loginfailed"/></p>:null}
                {this.props.sendingResetEmail?<ComponentLoader/>:null}
                 */}
                
                {this.state.passSuccess?<p style={{color:"#4fbfbd"}}><Trans i18nKey="login.passresetmessage"/> Please wait while we redirect you to the login page.</p>:null}
                {this.state.passwordMismatch?<p style={{color:"#B22222"}}><Trans i18nKey="Password Field empty or Password Mismatch"/></p>:null}
              </div>

          <div style={{width:"100%", display:"flex",flexDirection:"row",minHeight:"10%"}}>
                    <div style={{width:"50%",textAlign:"left"}}><a href="https://www.intelisa.in/" target="_blank" style={{ color:"gray",fontFamily:"Inter"}}><Trans i18nKey="login.aboutus"/></a></div>
                    <div style={{width:"50%",textAlign:"right"}}><a href="https://www.intelisa.in/#get-free-trial" target="_blank" style={{ color:"gray",fontFamily:"Inter"}}><Trans i18nKey="login.contactus"/></a></div>
                  </div>
          </div>
      </div>
     
    </div>
  </div>
    );


  }

  }
}
import axios from "../../utils/axiosInstance";
import moment from "moment";
import momentTZ from "moment-timezone";
import {SCREEN_TYPE,DATA_PROVIDER} from "../../../constants/CONSTANTS"
export function fetchScreensAdmin() {
  // return (dispatch) => {
  //   dispatch({ type: "FETCH_SCREENS_ADMIN" })
  //   axios.get("/api/admin/getScreens").then(function (response) {

  //     dispatch({type:"FETCH_SCREENS_ADMIN_SUCCESS",payload:response.data})
  //   })

  // }
  return (dispatch) => {
    dispatch({ type: "FETCH_SCREENS_ADMIN" })
    var loggedInUserRole = JSON.parse(localStorage.getItem("userObj")).role;
    var arrTemp = [];
    if(loggedInUserRole=="001"){
      axios.post("/api/admin/getLoggedInUserSubordinates",{loggedInUserRole}).then(function (response) {
        var subordinates = response.data;        
        subordinates.forEach((obj)=>{
          arrTemp.push(obj._id);
        });
        axios.post("/api/admin/getScreens",{arrTemp}).then(function (response) {

      dispatch({type:"FETCH_SCREENS_ADMIN_SUCCESS",payload:response.data})
    })
      });
    }else{
      axios.post("/api/admin/getScreens",{arrTemp}).then(function (response) {

      dispatch({type:"FETCH_SCREENS_ADMIN_SUCCESS",payload:response.data})
    })
  }

  }
  }

export function addScreen(screen){
  return (dispatch)=>{
      dispatch({ type: "ADD_SCREEN" })
    axios.post(`/api/addScreen`, {
      screenIdentifier:screen.screenIdentifier,
      screenName: screen.screenName,
      deviceIMEI:screen.deviceIMEI,
      dataProvider:screen.dataProvider,
      scnPadding:screen.scnPadding,
      simNumber:screen.simNumber,
      screenType:screen.screenType,
      spotCode:screen.spotCode,
      address:screen.address,
        zone:screen.zone,
        primeStartTime:screen.primeStartTime,
        primeEndTime:screen.primeEndTime,
      coordinates:screen.coordinates,
      tags:screen.tags,
      layout:screen.layout,      
      deviceIMEIModified:screen.deviceIMEIModified,
      resetFlag:screen.resetFlag,
      emailId:screen.emailId,
      philips:screen.philips,
      hardwareRotation:screen.hardwareRotation,
      ipAddress:screen.ipAddress,
      monitorIMEI:screen.monitorIMEI
    }
    ).then(response => {
      if(screen.screenIdentifier){
        dispatch({ type: "UPDATE_SCREEN_SUCCESS",payload:{
          data:response.data.data,screenIdentifier:screen.screenIdentifier

        } })
      }else{
        var userObj = JSON.parse(localStorage.getItem("userObj"));
        userObj.licensesTaken = response.data.licensesTaken;
        localStorage.setItem("userObj",JSON.stringify(userObj));
        dispatch({ type: "ADD_SCREEN_SUCCESS",payload:{
          data:response.data.data,          
          licensesTaken:response.data.licensesTaken
        } })
      }

    }).catch(error => {
      dispatch({ type: "ADD_SCREEN_FAILURE" })
    });
  }
}

export function fetchScreenTypes(selectedOwner) {
  return (dispatch) => {
    var manager_id = JSON.parse(localStorage.getItem("userObj")).manager_id;
    dispatch({ type: "FETCH_SCREEN_TYPES" })
    axios.post("/api/admin/getScreenTypes",{manager_id,selectedOwner}).then(function (response) {
      console.log("in action="+response.data)
      
      dispatch({type:"FETCH_SCREEN_TYPES_SUCCESS",payload:response.data})
    })

  }
  }

export function preserveParamsForSupportAdmin(selectedOwner,selectedOwnerName){
  var supportAdmin = !!selectedOwner ?true:false;
  return (dispatch) => {
  dispatch({ type: "PRESERVE_PARAMS_SUPPORT",selectedOwner, selectedOwnerName,supportAdmin})
  };
}

export function saveScreenType(screenType) {
  console.log("in save screen type="+screenType.screenTypeName)
    return (dispatch)=>{
      dispatch({ type: "ADD_SCREEN_TYPE" })
    axios.post("/api/addScreenType", {
      screenTypeName: screenType.screenTypeName
    }
    ).then(response => {
      console.log("in add screen success="+response.data)
          dispatch({ type: "ADD_SCREEN_TYPE_SUCCESS",payload:{
          data:response.data
     }
    })
   }).catch(error => {
    dispatch({ type: "ADD_SCREEN_TYPE_FAILURE" })
  });
}
}

export function removeScreenType(screenType) {

  return (dispatch)=>{
    axios.post("/api/removeScreenType",{
      screenTypeName: screenType.value
    }).then(function (response){

        dispatch({type:"REMOVE_SCREEN_TYPE_SUCCESS",payload:{
          screenTypeName: screenType.value
        }})
      })

  }
 
}

export function removeAdFromScheduleForDate(toDeleteData){
  return (dispatch)=>{

    dispatch({type:"FETCH_SCHEDULE_DATA"})
    axios.post("/api/removeAdFromScheduleForDate",{toDeleteData:toDeleteData}).then(function (response){

        // console.log("in remove="+response.data);
        // dispatch({type:"FETCH_SCHEDULE_DATA_SUCCESS",payload:response.data})
      })
      console.log("in remove ad from schedule")
  }
}

//To display on Schedule Management page and Screen schedule for all days
export function fetchScheduleData(screenId, imageRotation) {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  var todayDate = momentTZ.tz(moment().format("YYYY-MM-DD"), timeZone).clone().tz("Asia/Kolkata").format("YYYY-MM-DD");
  return (dispatch) => {
    dispatch({ type: "FETCH_SCHEDULE_DATA" })

    axios.post("/api/fetchScheduleData", { screenId: screenId, imageRotation,todayDate }).then(function (response) {
      dispatch({
        type: "FETCH_SCHEDULE_DATA_SUCCESS", payload: response.data
      })
    })

  }
}

//To display on Schedule Management page to display till 1 month before
export function fetchScheduleDataForCalendar(screenId, imageRotation) {
  return (dispatch) => {
    dispatch({ type: "FETCH_SCHEDULE_DATA" })

    axios.post("/api/fetchScheduleDataForCalendar", { screenId: screenId, imageRotation }).then(function (response) {
      dispatch({
        type: "FETCH_SCHEDULE_DATA_SUCCESS", payload: response.data
      })
    })

  }
}


export function saveScreensForUser(screenArrToUpdate) {
  return (dispatch) => {
    dispatch({ type: "SAVE_SCREENS_FOR_USER" })
    axios.post("/api/admin/saveScreensForUser",{screenArrToUpdate:screenArrToUpdate}).then(function (response) {
      
      dispatch({type:"SAVE_SCREENS_FOR_USER_SUCCESS",payload:response})
    })

  }
}

export function saveSelectedSplitScreenFile(screenNum,fileName) {
  return (dispatch) => {
    console.log("screenNum="+screenNum);
    console.log("fileName="+fileName);
    dispatch({ type: "SAVE_SPLIT_SCREEN_FILES",payload:{screenNum,fileName}})
    // axios.post("/api/admin/saveScreensForUser",{screenArrToUpdate:screenArrToUpdate}).then(function (response) {

    //   dispatch({type:"SAVE_SCREENS_FOR_USER_SUCCESS",payload:response.data})
    // })

  }
}
export function clearSplitScreenDetails() {
  return (dispatch) => {
    dispatch({ type: "CLEAR_SPLIT_SCREEN_DETAILS"})
    // axios.post("/api/admin/saveScreensForUser",{screenArrToUpdate:screenArrToUpdate}).then(function (response) {

    //   dispatch({type:"SAVE_SCREENS_FOR_USER_SUCCESS",payload:response.data})
    // })

  }
}


export function splitScreenUpload(screenID,file1,file2,screen3text) {
  console.log("screenID="+screenID);
  console.log("file1="+file1);
  console.log("file2="+file2);
  console.log("screen3text="+screen3text);
  return (dispatch) => {
    dispatch({ type: "SPLIT_SCREEN_UPLOAD"})
      axios.post("/api/splitScreenUpload",{screenID,file1, file2, screen3text}).then(function (response) {

       dispatch({type:"SPLIT_SCREEN_UPLOAD_SUCCESS",payload:response.data})
     })

  }
}

export function fetchTags(selectedOwner) {
  var manager_id = JSON.parse(localStorage.getItem("userObj")).manager_id;
  return (dispatch) => {
    dispatch({ type: "FETCH_TAGS" })
    axios.post("/api/admin/getTags",{manager_id,selectedOwner}).then(function (response) {
      console.log("in action="+response.data)
      dispatch({type:"FETCH_TAGS_SUCCESS",payload:response.data})
    })

  }
  }

  export function addTag(tagObj){
    return (dispatch)=>{
        dispatch({ type: "ADD_TAG" })
        axios.post(`/api/admin/addTag`, {
          tagIdentifier:tagObj.tagIdentifier,
          tagName : tagObj.tagName,
          createdDate:tagObj.createdDate,
          description:tagObj.description
      }
      ).then(response => {
        if(tagObj.tagIdentifier){
          dispatch({ type: "UPDATE_TAG_SUCCESS",payload:{
            data:response.data.data,tagIdentifier:tagObj.tagIdentifier
  
          } })
        }else{
          dispatch({ type: "ADD_TAG_SUCCESS",payload:{
            data:response.data.data
  
          } })
        }
  
      }).catch(error => {
        dispatch({ type: "ADD_TAG_FAILURE" })
      });
    }
  }  

  export function fetchLocations(selectedOwner) {
    console.log("in fetchLocations selectedOwner="+selectedOwner)
    return (dispatch) => {
      var manager_id = JSON.parse(localStorage.getItem("userObj")).manager_id;
      dispatch({ type: "FETCH_LOCATIONS" })
      axios.post("/api/admin/getLocations",{manager_id,selectedOwner}).then(function (response) {
        console.log("in action="+response.data)
        dispatch({type:"FETCH_LOCATIONS_SUCCESS",payload:response.data})
      })
  
    }
    }  
    export function removeLocation(location) {

      return (dispatch)=>{
        axios.post("/api/removeLocation",{
          locationName: location.value
        }).then(function (response){
    
            dispatch({type:"REMOVE_LOCATION_SUCCESS",payload:{
              locationName: location.value
            }})
          })
    
      }
     
    }    
    export function saveLocation(location) {
      console.log("in save location="+location.locationName)
        return (dispatch)=>{
          dispatch({ type: "ADD_LOCATION" })
        axios.post("/api/addLocation", {
          locationName: location.locationName
        }
        ).then(response => {
          console.log("in add location success="+response.data)
              dispatch({ type: "ADD_LOCATION_SUCCESS",payload:{
              data:response.data
         }
        })
       }).catch(error => {
        dispatch({ type: "ADD_LOCATION_FAILURE" })
      });
    }
    }    
    export function updateDeviceDebugResetFlag(deviceIMEI,resetFlag) {
      console.log("in method="+deviceIMEI,resetFlag)
      return (dispatch)=>{
        axios.post("/api/device/updateDeviceDebugResetFlags",{
          deviceIMEI: deviceIMEI,
          resetFlag:(resetFlag==null || resetFlag==0)?1:0
        }).then(function (response){
    
            dispatch({type:"UPDATE_RESET_FLAG_SUCCESS"})
          })
    
      }
     
    }   
    
    export function updateKiosk(deviceIMEI,kiosk) {
      console.log("in method="+deviceIMEI,kiosk)
      return (dispatch)=>{
        axios.post("/api/device/updateKiosk",{
          deviceIMEI: deviceIMEI,
          kiosk:(kiosk==null || kiosk==0)?1:0
        }).then(function (response){
    
            dispatch({type:"UPDATE_RESET_FLAG_SUCCESS"})
          })
    
      }
     
    }  

    export function updateDeviceDebugFlag(deviceIMEI,debugFlag) {
      console.log("in method="+deviceIMEI,debugFlag)
      return (dispatch)=>{
        axios.post("/api/device/updateDeviceDebugResetFlags",{
          deviceIMEI: deviceIMEI,
          debugFlag:(debugFlag==null || debugFlag==0)?1:0
        }).then(function (response){
    
            dispatch({type:"UPDATE_RESET_FLAG_SUCCESS"})
          })
    
      }
     
    }    
    export function saveSWVersionForScreens(screenArrToUpdate,swversion) {
      return (dispatch) => {
        dispatch({ type: "SAVE_SCREENS_FOR_USER" })
        axios.post("/api/admin/saveSWVersionForScreens",{screenArrToUpdate,swversion}).then(function (response) {
          
          dispatch({type:"SAVE_SCREENS_FOR_USER_SUCCESS",payload:response})
        })
    
      }
    }

    export function addSyncGroup(syncgroupObj) {
      return (dispatch) => {
        dispatch({ type: "ADD_SYNC_GROUP" })
        axios.post("/api/addSyncGroup",{
          tagName : syncgroupObj.tagName,
          description:syncgroupObj.description}).then(function (response) {
            console.log(response.data)
          dispatch({type:"ADD_SYNC_GROUP_SUCCESS",payload:response.data})
        })
      }
      
    }

    export function fetchSyncGroup() {
      var manager_id = JSON.parse(localStorage.getItem("userObj")).manager_id;
      return (dispatch) => {
        dispatch({ type: "FETCH_SyncGroup" })
        axios.post("/api/admin/getSyncGroup",{manager_id}).then(function (response) {
          console.log("in action="+JSON.stringify(response.data))
          dispatch({type:"FETCH_SyncGroup_SUCCESS",payload:response.data})
        })
    
      }
      }

    export function turnOnSyncGroup(action,syncgroupname,screendata) {
      console.log("in method="+syncgroupname,screendata)
      return (dispatch)=>{
        axios.post("/api/admin/turnOnSyncGroup",{
          syncgroupname: syncgroupname,
          screendata:screendata
        }).then(function (response){
          //dispatch(fetchSyncGroup())
            dispatch({type:"UPDATE_SYNC_GROUP_SUCCESS",syncgroupname,action})
          })
    
      }
      
    }

    export function turnOffSyncGroup(action,syncgroupname,screendata) {
      console.log("in method="+syncgroupname,action)
      console.log(screendata)
      return (dispatch)=>{
        axios.post("/api/admin/turnOffSyncGroup",{
          action: action,
          syncgroupname: syncgroupname,
          screendata:screendata
        }).then(function (response){
          //dispatch(fetchSyncGroup())
    
            dispatch({type:"UPDATE_SYNC_GROUP_SUCCESS",syncgroupname,action})
          })
    
      }
    }
    
    export function fetchUsersForConsolidatedReport() {
        return (dispatch) => {
          dispatch({ type: "FETCH_USERS" })
          axios.post("/api/admin/getUsersForConsolidatedReport").then(function (response) {
            console.log("in action="+response.data)
            dispatch({type:"FETCH_USERS_SUCCESS",payload:response.data})
          })
      
        }
        }  

        

export function saveTodayPrice(sunvikPrice,omSaiPrice){
  return (dispatch)=>{
    dispatch({type:"SAVING_TODAYPRICE"})
    axios.post("/api/saveTodayPrice",{sunvikPrice,omSaiPrice}).then(function (response){

        dispatch({type:"SAVE_TODAYPRICE_SUCCESS"})
      })

  }
}  

export function deleteScreen(screenID,slave,ticker){
          console.log("***screenID="+screenID)
          return (dispatch)=>{
            //dispatch({type:"FETCH_ADSLIST"})
            axios.post("/api/admin/deleteScreen",{screenID,slave,ticker}).then(function (response){
                console.log("delete screen action="+response.data.licensesTaken)
                dispatch({type:"DELETE_FROM_SCREEN_SUCCESS",payload:response.data})
              })
        
          }
        }    

        export function sendMailForLicense(userName){
          //var emailId = JSON.parse(localStorage.getItem("userObj")).emailId.toString();
          return (dispatch)=>{
            dispatch({type:"SEND_MAILFORLICENSE"})
            axios.post("/api/sendMailForLicense",{userName}).then(function (response){
        
                dispatch({type:"SEND_MAILFORLICENSE_SUCCESS",payload:response.data})
              })
        
          }
        }
        export function fetchAllowedLicenses(userID){
          console.log("fetchAllowedLicenses userID="+userID);
          
          return (dispatch)=>{
            dispatch({type:"FETCHING_ALLOWED_LICENSES"});
            axios.post("/api/getMyPlan",{
              userID
            }).then(function (response){
              console.log("***response.data="+JSON.stringify(response.data));
              //dispatch(fetchSyncGroup())
                dispatch({type:"FETCH_ALLOWED_LICENSES",payload:response.data.length>0?response.data[0]:{}})
              })
        
          }
        }
  

        
import React,{Component} from "react";
//import ModalWrapper_NoClose from "../wrapper/ModalWrapper_NoClose";
import ModalWrapper from "../wrapper/ModalWrapper";

import {connect} from "react-redux";
@connect((store)=>{
    return {

    }
})

export default class VerificationCodeSetup extends Component {
    constructor () {
     super();
     this.state = {
     }
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){
    }

    closeModal = () =>{
        this.setState({showAddScreen:false,step:1});
    }

    mailSupport = () =>{
        console.log("Sending mail for License!");
        var userName = JSON.parse(localStorage.getItem("userObj")).userName;
        var emailId = JSON.parse(localStorage.getItem("userObj")).emailId;
        var supportEmailId = 'support@intelisa.in';
        var urlEncodedBody = 'Hi%2C%0D%0A%0D%0AI%20am%20having%20queries%2Fissues%20in%20setting%20up%20my%20digital%20signage%20screen.%0D%0ACould%20you%20help%3F%0D%0A%0D%0ARegards%2C%20%0D%0A'+userName;
        window.open('mailto:'+supportEmailId+'?subject=Support for setting up screen&body='+urlEncodedBody)
        //this.props.dispatch(sendMailForLicense(JSON.parse(localStorage.getItem("userObj")).userName));
        this.setState({openWarningModal:false});
      }

    render(){
        return(
            <ModalWrapper 
                closeHandler={()=>this.props.closeHandler()} 
                title="A guide to getting the verification code to set up your screen" style={{height:"80vh",width:"60vw"}}>
            <div style={{display:"flex",flexDirection:"column"}}>
                <div>Congratulations on taking your first step to setting up your digital signage.
                You can either use an Android TV or an Android box to set it up. In case you would like to enquire on any additional
                options (for Windows, Linux) or for recommended purchases, you can contact our <span style={{cursor:"pointer",textDecoration:"underLine"}} onClick={()=>this.mailSupport()}>support team here</span>.
                </div>
                <div style={{textDecoration:"underLine",paddingTop:"15px",fontWeight:"bold"}}>Step 1:</div>
                <div >Open your Google Playstore on the Android TV / box. You may need to register the first time you open it.</div>

                <div style={{textDecoration:"underLine",paddingTop:"15px",fontWeight:"bold"}}>Step 2:</div>
                <div >Search for the application "Intelisa Digital Signage" and install it. For reference, the icon should look as follows: 
                <br/><img src="../../../images/Logo_Small.png" height="40"/></div>

                <div style={{textDecoration:"underLine",paddingTop:"15px",fontWeight:"bold"}}>Step 3:</div>
                <div >Open the App. You will see a verification code being displayed on your screen.
                <br/>
                Enter this verification code in the "Verification Code” dialogue box on your screen setup menu. 
                <br/>
                <img src="../../../images/vercode.png" height="120"/></div>

                <div style={{textDecoration:"underLine",paddingTop:"15px",fontWeight:"bold"}}>Step 4:</div>
                <div >Your screen is now set up. The verification code on your digital signage screen should now disappear, indicating that you are all set up!</div>

                <div style={{textDecoration:"underLine",paddingTop:"15px"}}>In case of any issues, here are some troubleshooting tips:</div>
                <div><ul>
                    <li>In case of an Android box:
                    <ul>
                        <li>Check that the HDMI cable connected to the TV is firmly in place</li>
                        <li>Check that the TV’s input source is on switched to the HDMI which is plugged to the Android box</li>
                    </ul>
                    </li>
                    <li>Check that you are connected to the internet</li>
                </ul></div>
                <div>In case the issues persists, don't hesitate to <span style={{cursor:"pointer",textDecoration:"underLine"}} onClick={()=>this.mailSupport()}>Contact Us!</span></div>

            </div>
            <div style={{height:"5px"}}>&nbsp;</div>
            </ModalWrapper>
        )
    }
}
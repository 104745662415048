import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import ModalWrapper from "../wrapper/ModalWrapper";
import {   fetchMyScreensForDeleteLicenses } from "../../redux/actions/myScreenAction.js";
import { deleteLicenses, deleteScreensForOnlineCustomer} from "../../redux/actions/userAdminAction";
import {formatDataForDeleteLicenses} from '../../utils/formatData';
import DeleteSelectedLicensesRenderer from "../../components/admin/DeleteSelectedLicensesRenderer";
import DialogBox from "../../components/DialogBox";
import DialogBox4 from "../../components/DialogBox4";
import { connect } from "react-redux";
@connect((store) => {
  return {

    screensFetched: store.screenDetails.screensFetched,

    screensData: store.screenDetails.screensData,
  }
})
export default class Delete_Licenses extends Component {
  constructor(props) {

    super(props);
    this.prevScreenIDsToDelete = [];
    this.state={
        prevScreenIDsToDelete: [],
        screenIDsToDelete : [],
        newLicensesToDelete: [],
        userID:"",
        expiryDate:"",
        confirmDeleteLicense : false,
        deleteMessage:false,
        numLicensesToDelete:0,//needed for delete from Stripe
        source:"ADMIN",
        message:"",
        cancelConfirm : false,
        context: { componentParent: this },
        columnDefs: [
        {
            headerName: "",
            field: "screenID",
            cellRenderer: "deleteSelectedLicensesRenderer",
            colId: "params",
            cellClass: 'no-border',
            suppressNavigable: true,
          editable: false,
          flex:1,
          minWidth:80
        },
        {
            headerName: "Screen Name", field: "screenName",flex:2,minWidth: 160 ,
            headerTooltip: 'Screen Name',
            tooltipValueGetter: (params) => { return params.value},
        },
        {
            headerName: "Orientation", field: "orientation",flex:1,minWidth: 160 ,
            headerTooltip: 'Orientation',
            tooltipValueGetter: (params) => { return params.value},
        },
        {
            headerName: "Device IMEI", field: "deviceIMEI",flex:1,minWidth: 160 ,
            headerTooltip: 'Device IMEI',
            tooltipValueGetter: (params) => { return params.value},
        },
        {
            headerName: "Screen Type", field: "screenType",flex:1,minWidth: 160 ,
            headerTooltip: 'Screen Type',
            tooltipValueGetter: (params) => { return params.value},
        },
        {
            headerName: "Location", field: "location",flex:1,minWidth: 160 ,
            headerTooltip: 'Location',
            tooltipValueGetter: (params) => { return params.value},
        },
    ],
        rowData: [],
        frameworkComponents: {
            deleteSelectedLicensesRenderer: DeleteSelectedLicensesRenderer,
        }
    }
}

componentDidMount(){      
    console.log("***user ID="+this.props.selectedDataForDelete._id);
    console.log("***licensesToDelete="+JSON.stringify(this.props.selectedDataForDelete));
    if(this.props.selectedDataForDelete.deletelicenses){
      console.log("****Setting prev again!"+JSON.stringify(this.props.selectedDataForDelete.deletelicenses.licensesToDelete))
      this.prevScreenIDsToDelete = this.props.selectedDataForDelete.deletelicenses.licensesToDelete;
      this.setState({prevScreenIDsToDelete:this.props.selectedDataForDelete.deletelicenses.licensesToDelete,
        screenIDsToDelete:this.props.selectedDataForDelete.deletelicenses.licensesToDelete,
        numLicensesToDelete:this.props.selectedDataForDelete.deletelicenses.licensesToDelete.length});
    }
    if(this.props.selectedDataForDelete.customerdetails){
      this.setState({expiryDate:this.props.selectedDataForDelete.customerdetails.expiryDate});
    }
    this.setState({userID:this.props.selectedDataForDelete._id})
    this.props.dispatch(fetchMyScreensForDeleteLicenses(this.props.selectedDataForDelete._id))
}

getSource=()=>{
  return this.props.source;
}

getSelectedStatusForLicense = (rowIndex)=>{
  console.log("***rowIndex="+rowIndex)
  const selectedNodes = this.gridApi.getDisplayedRowAtIndex(rowIndex);
    const selectedDataForDelete = selectedNodes.data;
    console.log("***getting status this.state.screenIDsToDelete="+JSON.stringify(this.state.screenIDsToDelete));
    console.log("**selectedData._id="+JSON.stringify(selectedDataForDelete))
  if(this.state.screenIDsToDelete.includes(selectedDataForDelete.screenID)){
    return true;
  }else{
    return false;
  }
}


onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
 params.api.sizeColumnsToFit();

 //this.gridApi.showLoadingOverlay();

  };

componentWillReceiveProps(nextProps){
    var data = formatDataForDeleteLicenses(nextProps.screensData);
  console.log("****Data="+JSON.stringify(data));
  console.log("**in compwillrecprops prevScreenIDsToDelete"+this.state.screenIDsToDelete)
  this.setState({rowData:data});
}
deleteSelectedLicense = (rowIndex,status) =>{
  // var prevScreenIDsToDelete = this.state.prevScreenIDsToDelete;
  // console.log("****deleteSelectedLicense prevScreenIDsToDelete="+prevScreenIDsToDelete)
  console.log("****deleteSelectedLicense screenIDsToDelete="+this.state.screenIDsToDelete)
    const selectedNodes = this.gridApi.getDisplayedRowAtIndex(rowIndex);
    const selectedData1 = selectedNodes.data;
    console.log("***selectedData1="+JSON.stringify(selectedData1));
    var screenIDsToDelete = this.state.screenIDsToDelete;
    var newLicensesToDelete = this.state.newLicensesToDelete;
    if(status){
      console.log("Add to list");
      if(!screenIDsToDelete.includes(selectedData1.screenID)){
        //screenIDsToDelete.push(selectedData1.screenID);
        screenIDsToDelete = screenIDsToDelete.concat(selectedData1.screenID)
      } 
      
      if(!newLicensesToDelete.includes(selectedData1.screenID))  {
        newLicensesToDelete.push(selectedData1.screenID);
      }   
    } else {
      //var indexOfElement =  screenIDsToDelete.indexOf(selectedData1.screenID);     
       //screenIDsToDelete.splice(indexOfElement,1);
       screenIDsToDelete = this.state.screenIDsToDelete.filter((obj)=>{
        return obj!==selectedData1.screenID
       })
        
       newLicensesToDelete.splice(screenIDsToDelete.indexOf(selectedData1.screenID),1);
    }
    console.log("screenIDsToDelete="+JSON.stringify(screenIDsToDelete));
    console.log("&&&prev ids here="+JSON.stringify(this.state.prevScreenIDsToDelete))
    this.setState({screenIDsToDelete,newLicensesToDelete});//prevScreenIDsToDelete
}
saveDeletedLicenses = ()=>{
  console.log("To save deleted licenses!");
  this.props.dispatch(deleteLicenses({
    selectedUserId:this.state.userID,
    expiryDate:this.state.expiryDate,
    licensesToDelete:this.state.screenIDsToDelete
  }));
  this.setState({confirmDeleteLicense:false});
  this.props.closeHandler();
}

deleteScreensForOnlineCustomer = ()=>{
  console.log("deleteScreensForOnlineCustomer***this.state.screenIDsToDelete="+this.state.screenIDsToDelete)
  this.props.dispatch(deleteScreensForOnlineCustomer({
    selectedUserId:this.state.userID,
    licensesToDelete:this.state.screenIDsToDelete
  }));
  this.setState({confirmDeleteLicense:false});
  this.props.closeHandler();
}
confirmDelete = () =>{
  console.log("****");
  this.setState({confirmDeleteLicense:true,deleteMessage:false,message:false});
}

resetDeletedScreens = () =>{  
  if(this.props.source=="MYSCREENS" || this.props.source=="MYPLAN"){
    console.log("To delete screens="+this.state.screenIDsToDelete)
    console.log("To delete screens="+this.state.prevScreenIDsToDelete)
    console.log("***#"+this.props.selectedDataForDelete.deletelicenses.licensesToDelete)
    this.setState({screenIDsToDelete:this.prevScreenIDsToDelete});
    
  } else {
    var screenIDsToSetBack = this.state.screenIDsToDelete;
    console.log("***1st screenIDsToSetBack="+JSON.stringify(screenIDsToSetBack))
    console.log("**this.state.newLicensesToDelete="+JSON.stringify(this.state.newLicensesToDelete))
    this.state.newLicensesToDelete.map((obj)=>{
      var indexOfElement = screenIDsToSetBack.indexOf(obj);
      screenIDsToSetBack.splice(indexOfElement,1);
    })
    console.log("***2nd screenIDsToSetBack="+JSON.stringify(screenIDsToSetBack))
    this.setState({screenIDsToDelete:screenIDsToSetBack});    
    this.props.closeHandler();    
    }
    
    
}
cancelOrCloseCheck = () =>{
  if(this.state.screenIDsToDelete.length>this.state.numLicensesToDelete){
    this.setState({message:"You have to select more than "+ this.state.numLicensesToDelete +" screen(s) to be deleted."});
  } else if(this.state.screenIDsToDelete.length==0){
    this.setState({message:"You have not selected any screens to be deleted.\n"});
  } else {
    this.setState({message:""});
  }
  this.setState({deleteMessage:true,confirmDeleteLicense:false});
}

deleteLastCreatedScreens = () =>{
  this.resetDeletedScreens();
  setTimeout(() => {
    this.deleteScreensForOnlineCustomer();
  }, 500);
  
}
render() {
    return (
        <ModalWrapper closeHandler={()=>this.props.source=="MYSCREENS" || this.props.source=="MYPLAN"?this.cancelOrCloseCheck():this.setState({cancelConfirm:true})} 
      title="Delete Licenses" style={{height:"100vh",width:"90vw"}}>
        <div class="container-fluid pt-5" style={{border:"0px solid black",height:"90%",width:"95%"}}>
        <div>{this.props.source=="MYPLAN"?
          <div style={{fontFamily:"Inter"}}> You are now subscribed to {this.state.rowData.length} screen license(s). Please select {this.state.numLicensesToDelete} screen(s) from the table below to be deleted.</div>:null}
        </div>
        <div
        className="ag-theme-material mt-2 flex-1"
        style={{ height:"60vh",width:"95%"}}
      >
        <AgGridReact
            defaultColDef={{
            sortable: true,
            filter: true,
            pagination:true
            }}

            context={this.state.context}
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={this.onGridReady}
            onFirstDataRendered={this.onFirstDataRendered}
            onRowClicked={this.onRowClicked}
            columnDefs={this.state.columnDefs}
            suppressHorizontalScroll={true}
            overlayLoadingTemplate={'<div class="d-flex align-items-center justify-content-center componentloader " > <figure></figure><figure></figure> <figure></figure> <figure></figure><figure></figure></div>'}
            rowData={this.state.rowData}
            localeText={eval("AG_GRID_LOCALE_"+localStorage.getItem("locale").toUpperCase())}
         >
        </AgGridReact>
        <div>{this.props.source=="MYPLAN"?
          <div style={{fontSize:"16px",fontFamily:"Inter"}}> Note: If you do not select any screens, the last created screen(s) will be deleted by default.</div>:null}
        </div>
        </div>
        {this.state.cancelConfirm?
            <DialogBox msg="Are you sure you want to exit?">
            <button class="btn btn-warning" onClick={() => {this.setState({cancelConfirm:false})}} >Cancel</button>
            <button class="btn btn-info" onClick={() => {this.props.closeHandler();}} >OK</button>
          </DialogBox>
          :null}
        {this.state.confirmDeleteLicense?  
        <DialogBox msg={this.props.source=="MYSCREENS" || this.props.source=="MYPLAN"?"Are you sure you want to delete the selected licenses?":"Are you sure you want to delete "+this.state.newLicensesToDelete.length+" license(s)?"}>
        <button class="btn btn-info" onClick={() => this.props.source=="MYSCREENS" || this.props.source=="MYPLAN"?this.deleteScreensForOnlineCustomer():this.setState({deleteMessage:true})
        } >Confirm</button>
        <button class="btn btn-warning" onClick={()=>this.setState({confirmDeleteLicense:false})}>Cancel</button>
        </DialogBox>:null}

        {this.state.deleteMessage?  (
          this.props.source=="MYSCREENS" || this.props.source=="MYPLAN"?
          <DialogBox4 msg={this.state.message} msg1="The last created screen(s) will be deleted by default. Do you want to continue?">
            <button class="btn btn-info" onClick={() => {this.deleteLastCreatedScreens();this.setState({deleteMessage:false})}} >OK</button>
            <button class="btn btn-warning" onClick={()=>this.setState({deleteMessage:false})}>Cancel</button>
          </DialogBox4>
          :
          <DialogBox msg="The selected screen(s) will be deleted at the end of your current billing cycle.">
            <button class="btn btn-info" onClick={() => {this.saveDeletedLicenses();this.setState({deleteMessage:false})}} >OK</button>
          </DialogBox>
          ):null}
          
        </div>
        
        <div class="d-flex justify-content-center align-items-center btn-group" style={{height:"90px"}}>
        {((this.props.source=="MYPLAN"  || this.props.source=="MYSCREENS") && this.state.numLicensesToDelete!==this.state.screenIDsToDelete.length)?
        <div style={{color:'red'}}>Please select only {this.state.numLicensesToDelete} screen(s) to be deleted!&nbsp;&nbsp;</div>
        :null}
        <button class="btn btn-lite" onClick={()=>this.props.source=="MYSCREENS" || this.props.source=="MYPLAN"?this.cancelOrCloseCheck():this.setState({cancelConfirm:true})}>Cancel</button>
        <button class="btn btn-info" onClick={()=>this.confirmDelete()} disabled={(this.props.source=='ADMIN' && this.state.newLicensesToDelete.length==0)
        || ((this.props.source=="MYPLAN" || this.props.source=="MYSCREENS") && this.state.numLicensesToDelete!==this.state.screenIDsToDelete.length)}>Delete</button>
        </div>
       
        </ModalWrapper>
        
    )
}
}

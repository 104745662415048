import update from 'immutability-helper';
export default function reducer(state = {

  screensFetching: false,
  screensFetched: false,

  screensData: [],
  scheduleFetching:false,
  scheduleFetched:false,
  scheduleList:null,
  screenOverview:{},
  savingSchedule:false,
  savedSchedule:false,
  socialMediaList : [],
  socialMediaFetching:false,
  socialMediaFetched:false,
  screenSaverSaved : false,
  screenSaverSaving : false,
  screenLayoutSaving:false,
  screenLayoutSaved:false,
  adsDownloadStatusFetched: false,
  adsDownloadStatusFetching: false,
  adsDownloadData:[],
  mirroringInfoSaving:false,
  mirroringInfoSaved:false,
  savedConfSchedule:false,
  loginDetailsSaving:false,
  loginDetailsSaved:false,
  impressionsDataFetching:false,
  impressionsDataFetched:false,
  impressionsData:[],
  screenWidgetSaved : false,
  screenWidgetSaving : false,
  savedDeleteAds : false,
  lastUploadedAd:"",
  lastUploadedCount:0,
  lastUploadedAdPath:"",
  deletedLicenses:[],
  stepsDoneArr:[]
}, action) {

  switch (action.type) {
    case "CLEAR_MYSCREEN_DATA":{
      return {
        ...state,
        screenOverview:{},
        scheduleFetching:false,
        scheduleFetched:false,
        scheduleList:null,
      }
    }
    case "SAVE_SCREEN_SCHEDULE":{
      return {
        ...state,savingSchedule:true,savedSchedule:false,savedConfSchedule:false
      }
    }
    case "SAVE_SCREEN_SCHEDULE_SUCCESS":{
      return {
        ...state,savingSchedule:false,savedSchedule:true,savedConfSchedule:false
      }
    }
    case "SAVE_AD_SCREEN_STATUS":{
      return {
        ...state
      }
    }
    case "SAVE_AD_SCREEN_STATUS_SUCCESS":{
      return {
        ...state
      }
    }
    case "CLEAR_SAVE_SCHEDULE_STATE":{
      return {
        ...state,savingSchedule:false,savedSchedule:false
      }
    }
    case "FETCH_SCREEN_OVERVIEW":{
      return {
        ...state,
        screenOverview:{}
      }
    }
    case "FETCH_SCREEN_OVERVIEW_SUCCESS":{
      return {
        ...state,
      screenOverview:action.payload
      }
    }


      case "FETCH_SCREENS_DATA":
      return {
        ...state,
        screensFetched: false,
        screensFetching: true,
		screensData:[],
      }

      case "FETCH_SCREENS_DATA_SUCCESS":
      return {
        ...state,
        screensFetched: true,
        screensFetching: false,
        screensData:action.payload
      }

      case "FETCH_AD_DOWNLOAD_STATUS":
      return {
        ...state,
        adsDownloadStatusFetched: false,
        adsDownloadStatusFetching: true
      }

      case "FETCH_AD_DOWNLOAD_STATUS_SUCCESS":
      return {
        ...state,
        adsDownloadStatusFetched: true,
        adsDownloadStatusFetching: false,
        adsDownloadData:action.payload
      }

      case "FETCH_SCHEDULE_LIST":
      return{
        ...state,
        scheduleFetching:true,
        scheduleFetched:false,
      }
      case "FETCH_SCHEDULE_LIST_SUCCESS":
      return{
        ...state,
        scheduleFetching:false,
        scheduleFetched:true,
        scheduleList:action.payload?action.payload.schedule:[]
      }

      case "FETCH_SOCIAL_MEDIA":
      return{
        ...state,
        socialMediaFetching:true,
        socialMediaFetched:false,
      }
      case "FETCH_SOCIAL_MEDIA_SUCCESS":
      return{
        ...state,
        socialMediaFetching:false,
        socialMediaFetched:true,
        socialMediaList:action.payload
      }

      case "SAVE_SCREEN_SAVER":{
        return {
          ...state,screenSaverSaving:true,screenSaverSaved:false
        }
      }
      case "SAVE_SCREEN_SAVER_SUCCESS":{
        return {
          ...state,screenSaverSaving:false,screenSaverSaved:true
        }
      }

      case "SAVE_LAYOUT":{
        return {
          ...state,screenLayoutSaving:true,screenLayoutSaved:false
        }
      }
      case "SAVE_LAYOUT_SUCCESS":{
        return {
          ...state,screenLayoutSaving:false,screenLayoutSaved:true
        }
      }

      case "SAVE_MIRRORING_INFO":{
        return {
          ...state,mirroringInfoSaving:true,mirroringInfoSaved:false
        }
      }
      case "SAVE_MIRRORING_INFO_SUCCESS":{
        return {
          ...state,mirroringInfoSaving:false,mirroringInfoSaved:true
        }
      }
      case "SAVE_CONF_SCREEN_SCHEDULE_SUCCESS":{
        return {
          ...state,savingSchedule:false,savedConfSchedule:true
        }
      }
      case "SAVE_LOGIN_DETAILS":{
        return {
          ...state,loginDetailsSaving:true,loginDetailsSaved:false
        }
      }
      case "SAVE_LOGIN_DETAILS_SUCCESS":{
        return {
          ...state,loginDetailsSaving:false,loginDetailsSaved:true
        }
      }

      case "FETCH_IMPRESSIONS_DATA":
        return{
          ...state,
          impressionsDataFetching:true,
          impressionsDataFetched:false,
        }
        case "FETCH_IMPRESSIONS_DATA_SUCCESS":
          console.log("action.payload="+action.payload)
        return{
          ...state,
          impressionsDataFetching:false,
          impressionsDataFetched:true,
          impressionsData:action.payload
        }
        case "SAVE_DELETEADS":{
          return {
            ...state,savedDeleteAds:false
          }
        }

	      case "SAVE_DELETEADS_SUCCESS":{
        return {
          ...state,savedDeleteAds:true
        }
      }
case "GET_LAST_UPLOADED_AD":{
        return {
          ...state,
          lastUploadedAd:action.payload.fileName,
          lastUploadedCount:action.payload.count,
          lastUploadedAdPath:action.payload.adPath,
        }
      }
      
      case "GET_DELETED_LICENSES":{
        return {
          ...state,
          deletedLicenses:action.payload
        }
      }
    case "CHECK_FIRST_STEPS":{
      console.log("***action.payload stepsDoneArr="+JSON.stringify(action.payload.stepsDoneArr))
      return {
        ...state,
        stepsDoneArr:action.payload.stepsDoneArr
      }
    }

  }
  return state;
}

import React,{Component} from "react";
import "../../css/widgets/widget.css";
export default class DialogBoxLongMessage extends Component {
  render(){
    return(
      <div class="modal d-flex align-items-center justify-content-center" style={{zIndex:1500,backgroundColor:"#0000009e"}} >
      <div class="card  widget bg-white" {...this.props} style={{width:"90vh"}}>
  <div class="card-header d-none">
  <div class="card-caption">
  <div class="card-icon">

  </div>
  <div class="card-title">
  <span class="main-title"></span>
    <span class="sub-title"></span>
  </div>
  </div>

  <div class="action-buttons">
  <a class="btn btn-circle d-none btn-icon-only"><i class="material-icons">cloud_download</i></a>
  <a class="btn btn-circle btn-icon-only"><img src="images/alert.png" width="30%"/></a>
    <a class="btn btn-circle  btn-icon-only" onClick={()=>this.props.closeHandler()}><i class="fa fa-times"></i></a>

  </div>
  </div>
  <div style={{display:"flex",flexDirection:"row"}}>
  <div style={{width:"10%",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",border:"0px solid black"}}>
  <img src="images/alert.png" width="60%"/></div>
  <div style={{width:"90%"}}>
  <div style={{width:"100%",border:"0px solid red"}} class="card-body p-4 align-items-center justify-content-start">
  {this.props.msg1}<button style={{verticalAlign:"top",background:"transparent",textDecoration:"underLine",border:"0px solid red",padding:"0px"}} class="btn" onClick={()=>this.props.btnClickMethod()}>{this.props.btnText}</button>{this.props.msg1Continued}
  </div>
  <div class="card-body pl-4  align-items-center d-flex justify-content-start">
  {this.props.msg2}
  </div>
  </div>
  </div>
 <div class="card-footer btn-group btn-group-spacer bg-white d-flex justify-content-end align-items-center" style={{maxHeight:"60px"}}>
  {this.props.children}
  </div>
</div>
  </div>
    )
  }
}

import React,{Component} from "react";
//import ModalWrapper_NoClose from "../wrapper/ModalWrapper_NoClose";
import ModalWrapper from "../wrapper/ModalWrapper";
import {saveLocation,fetchLocations, removeLocation, 
    saveScreenType,fetchScreenTypes, removeScreenType,addScreen} from "../../redux/actions/screenAdminAction"
import Add_Update_Screen_AccountSetup from "./Add_Update_Screen_AccountSetup";
import {connect} from "react-redux";
@connect((store)=>{
    return {
        locationStatus : store.screensAdmin.locationStatus,
        locationData: store.screensAdmin.locationData,
        locationsFetched: store.screensAdmin.locationsFetched,
        addScreenTypeStatus : store.screensAdmin.addScreenTypeStatus,
        screenTypesFetched: store.screensAdmin.screenTypesFetched,
        screenTypeData: store.screensAdmin.screenTypeData,
    }
})

export default class AccountSetup extends Component {
    constructor () {
     super();
     this.state = {
        location:"",
        locationData:[],
        step:0,
        screenType:"",
        screenTypeData:[],
        showAddScreen:false,
        screenValidated:false
     }
    }

    componentDidMount(){
        this.props.dispatch(fetchLocations());    
        this.props.dispatch(fetchScreenTypes())
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.locationsFetched){
            this.setState({locationData:nextProps.locationData})
        }
        if(nextProps.screenTypesFetched){
            this.setState({screenTypeData:nextProps.screenTypeData})
        }
        if(nextProps.locationStatus==1){
            this.setState({location:""})
        }
        if(nextProps.addScreenTypeStatus==1){
            this.setState({screenType:""})
        }
    }

    setFieldValue=({target})=>{
        this.setState({location:target.value})
    }

    setScreenType=({target})=>{
        this.setState({screenType:target.value})
    }

    addLocation = () =>{
    if(this.state.location!==""){
        var locationExists = this.state.locationData.filter(obj=>obj.value==this.state.location);
        if(locationExists.length==0){
            this.props.dispatch(saveLocation({
                locationName: this.state.location,
        
        }));        
        }
            
    }
}

    removeLocation = (location) => {
        this.props.dispatch(removeLocation({value:location}));
    }
    removeScreenType = (screenType) => {
    this.props.dispatch(removeScreenType({value:screenType}));
    }      

      addScreenType = () =>{
        if(this.state.screenType!==""){
            var locationExists = this.state.locationData.filter(obj=>obj.value==this.state.location);
            if(locationExists.length==0){
                this.props.dispatch(saveScreenType({
                    screenTypeName: this.state.screenType,           
            }))       
            }
                
        }
    }      
    
    goToNextStep = () =>{
        this.setState({step:this.state.step+1})
    }

    goToPreviousStep = () =>{
        this.setState({step:this.state.step-1})
    }

    addNewScreen = () =>{
        console.log("to show add screen!")
        this.setState({showAddScreen:true,step:-1});
    }

    closeModal = () =>{
        this.setState({showAddScreen:false,step:1});
    }

    enableSave = (save)=>{
        console.log("SAVE IS ="+save)
        this.setState({screenValidated:save});
    }

    saveScreen = ()=>{
        console.log("Saving screen");
        var screenDetails = this.addScreenModal.getScreenDetails();
        this.props.dispatch(addScreen(screenDetails));
        this.setState({showAddScreen:false,step:3});
    }

    render(){
        return(
            <ModalWrapper 
                closeHandler={()=>this.props.closeHandler()} 
                title="Account Setup" style={{height:"80vh",width:"60vw"}}>
            {this.state.step==0?
            <div class="d-flex w-80  py-2" style={{flexDirection:"column",width:"80%",alignItems:"center",height:"100%"}}>
            <div style={{height:"15%"}}>
                <img src="../../images/accountsetup_1.png" height="50px" style={{paddingTop:"10px"}}/>
            </div>
            <div style={{maxHeight:"40%",height:"40%",fontFamily:"Inter",fontSize:"22px",width:"75%",paddingTop:"10px",overflow:"hidden",border:"0px solid black"}}>
                What locations (City or area) would your screens be present in to start with? Enter as many locations as you would
                like. This would help us categorize your screens by location. You can always add more later.
            </div>
            <div style={{height:"15%",border:"0px solid black",width:"75%",paddingTop:"25px",display:"flex",flexDirection:"row"}}>
                <div style={{width:"50%",display:"flex",flexDirection:"row",alignItems:"baseline"}}>
                <input type="text" value={this.state.location} id="location" onChange={this.setFieldValue}  placeholder="Add Location" size="30" style={{height:"40px",border:'1px solid #D3D3D3',borderRadius:"5px"}}/>
                </div>
                <div style={{width:"50%",display:"flex",flexDirection:"row",alignItems:"baseline",paddingLeft:"10px"}}><button style={{border:"0px",background:"transparent",cursor:"pointer"}} onClick={()=>this.addLocation()}>
                <img src="../../images/accountsetup_add.png" height="35px"/></button></div>
                </div>
                <div style={{height:"35%",display: "flex",flexDirection:"row",flexWrap:"wrap",gap:"10px",width:"75%",border:"0px solid red"}}>
                {this.state.locationData.map(obj=>
                <div key={obj.value} style={{height:"30px",padding:"5px",border:'1px solid #D3D3D3',borderRadius:"15px",fontFamily:"Inter",fontSize:"14px"}}>
                {obj.label}&nbsp;&nbsp;&nbsp;&nbsp;<img src="../../images/accountsetup_remove.png" height="15px" style={{cursor:"pointer"}} onClick={()=>this.removeLocation(obj.value)}/></div>
                )}
                </div>
            </div>
            :this.state.step==1?
            <div class="d-flex w-80  py-2" style={{flexDirection:"column",width:"80%",alignItems:"center"}}>
            <div style={{height:"15%"}}>
                <img src="../../images/accountsetup_12.png" height="50px" style={{paddingTop:"10px"}}/>
            </div>
            <div style={{height:"30%",fontFamily:"Inter",fontSize:"22px",width:"75%",paddingTop:"10px"}}>
                What type of screens would you be adding to start with? (Eg. Indoor, Outdoor, and so on) This would help us
                categorize your screens by type. You can always add more later.
            </div>
            <div style={{height:"20%",border:"0px solid black",width:"75%",paddingTop:"25px",display:"flex",flexDirection:"row"}}>
            <div style={{width:"50%",display:"flex",flexDirection:"row",alignItems:"baseline"}}>
            <input type="text" value={this.state.screenType} id="screenType" onChange={this.setScreenType}  placeholder="Screen Type" size="30" style={{height:"40px",border:'1px solid #D3D3D3',borderRadius:"5px"}}/>
            </div>
            <div style={{width:"50%",display:"flex",flexDirection:"row",alignItems:"baseline",paddingLeft:"10px"}}><button style={{border:"0px",background:"transparent",cursor:"pointer"}} onClick={()=>this.addScreenType()}>
            <img src="../../images/accountsetup_add.png" height="35px"/></button></div>
            </div>
            <div style={{height:"35%",display: "flex",flexDirection:"row",flexWrap:"wrap",gap:"10px",width:"75%",border:"0px solid red"}}>
            {this.state.screenTypeData.map(obj=>
            <div key={obj.value} style={{height:"30px",padding:"5px",border:'1px solid #D3D3D3',borderRadius:"15px",fontFamily:"Inter",fontSize:"14px"}}>
            {obj.label}&nbsp;&nbsp;&nbsp;&nbsp;<img src="../../images/accountsetup_remove.png" height="15px" style={{cursor:"pointer"}} onClick={()=>this.removeScreenType(obj.value)}/></div>
            )}
            </div>
            </div>
            :this.state.step==2?
            <div class="d-flex w-80  py-2" style={{flexDirection:"column",width:"80%",alignItems:"center"}}>
            <div style={{height:"15%"}}>
                <img src="../../images/accountsetup_123.png" height="50px" style={{paddingTop:"10px"}}/>
            </div>
            <div style={{height:"20%",fontFamily:"Inter",fontSize:"22px",width:"75%",paddingTop:"10px"}}>
                Let’s help you setup your first screen.
            </div>
            <div style={{height:"20%",border:"0px solid black",width:"75%",paddingTop:"25px",display:"flex",flexDirection:"row"}}>
                <button onClick={()=>this.addNewScreen()} style={{color:'white',background:'#B3272C',cursor:'pointer',width:"50%",height:"30px",fontFamily:'Inter',border:"0px",
                borderRadius:'5px'}}>Create your first screen</button>
            </div>
            </div>
            :this.state.showAddScreen?
            <Add_Update_Screen_AccountSetup screenTypeData={this.state.screenTypeData} 
                enableSave={(save)=>this.enableSave(save)} locationData={this.state.locationData} 
                onRef={ref => (this.addScreenModal = ref)} closeHandler={()=>this.closeModal()} />
            :this.state.step==3?
            <div class="d-flex w-80  py-2" style={{flexDirection:"column",width:"80%",alignItems:"center"}}>
            <div style={{height:"15%"}}>
                <img src="../../images/accountsetup_1234.png" height="50px" style={{paddingTop:"10px"}}/>
            </div>
            <div style={{height:"20%"}}>
            <img src="../../images/accountsetup_done.png" height="60px" style={{paddingTop:"10px"}}/>
            </div>
            <div style={{height:"30%",fontFamily:"Inter",fontSize:"22px",width:"70%",paddingTop:"10px",justifyContent:"center",textAlign:"center"}}>
            You are all set up! You can now go ahead and play content on your digital signage screen(s). Check out our
            tutorials for a step-by-step overview of our features.
            </div>
            <div style={{height:"20%",border:"0px solid black",width:"75%",paddingTop:"25px",display:"flex",flexDirection:"row",justifyContent:"center"}}>
                <button onClick={()=>this.props.closeHandler()} style={{color:'white',background:'#B3272C',cursor:'pointer',width:"50%",height:"30px",fontFamily:'Inter',border:"0px",
                borderRadius:'5px'}}>Get Started</button>
            </div>
            </div>
            :null}
            <div class="d-flex main justify-content-between" style={{width:"100%",height:"30px",display:"flex",border:"0px solid red"}}>
            {this.state.step==1 || this.state.step==2?
                <button class="btn btn-info"
                    style={{cursor:'pointer',background:"#176969",height:"40px",color:'white',width:"100px",border:"0px"}}
                    onClick={()=>this.goToPreviousStep()}>
                    Back
                </button>
            :<div>&nbsp;</div>}
            {this.state.step==0 || this.state.step==1 || (this.state.step==2 && this.state.screenValidated)?
                <button class="btn btn-info"
                    disabled={this.state.step==0?this.state.locationData.length==0:this.state.step==1?this.state.screenTypeData.length==0:false} 
                    style={{cursor:'pointer',background:"#176969",height:"40px",color:'white',width:"100px",border:"0px"}}
                    onClick={()=>this.goToNextStep()}>
                    Next&nbsp;&nbsp;<img src="../../images/next.png"/>
                </button>
            :null}
            {this.state.showAddScreen?
            <div>
                <button class="btn"
                    style={{cursor:'pointer',background:"#F0F0F0",height:"40px",color:'black',width:"100px",border:"0px"}}
                    onClick={()=>this.closeModal()}>
                    Cancel
                </button>
                &nbsp;&nbsp;
                <button class="btn btn-info"
                    disabled={!this.state.screenValidated} 
                    style={{cursor:'pointer',background:"#176969",height:"40px",color:'white',width:"100px",border:"0px"}}
                    //style={{cursor:'pointer',background:"#66CBC9",height:"40px",color:'white',width:"100px",border:"0px"}}
                    onClick={()=>this.saveScreen()}>
                    Save
                </button>
                </div>
            :null}
            </div>
            </ModalWrapper>
        )
    }
}
import React,{Component} from "react";
import "../../css/widgets/widget.css";
export default class DialogBox extends Component {
  render(){
    return(
      <div class="modal d-flex align-items-center justify-content-center" style={{zIndex:1500,backgroundColor:"#0000009e"}}>
        <div class="card  widget bg-white py-4" {...this.props}>
        <div class="action-buttons" style={{border:"0px solid red",justifyContent:"flex-end"}}>
              <a class="btn btn-circle d-none btn-icon-only"><i class="material-icons">cloud_download</i></a>
              <a class="btn btn-circle btn-icon-only" onClick={()=>this.props.closeHandler()}><i class="fa fa-times"></i></a>

            </div>
          <div class="card-header d-none">
            <div class="card-caption">
              <div class="card-icon align-items-center">
              
              </div>
              <div class="card-title">
                <span class="main-title"></span>
                <span class="sub-title"></span>
              </div>
            </div>

            <div class="action-buttons">
              <a class="btn btn-circle d-none btn-icon-only"><i class="material-icons">cloud_download</i></a>
              <a class="btn btn-circle btn-icon-only" onClick={()=>this.props.closeHandler()}><i class="fa fa-times"></i></a>

            </div>
          </div>
          <div class="card-body h4 align-items-center d-flex justify-content-center">
          
            <img src="images/Verified.png" height={40}/>
          </div>
          <div class="card-body align-items-center d-flex justify-content-center" style={{paddingTop:"3%",fontSize:"18px"}}>
            {this.props.msg}
          </div>
          <div class="card-body align-items-center d-flex justify-content-center" style={{color:"gray"}}>
            {this.props.msg1}
          </div>
          <div class="card-body align-items-center d-flex justify-content-center" style={{color:"gray"}}>
            {this.props.msg2}
          </div>
          <div class="btn-group btn-group-spacer bg-white d-flex justify-content-center align-items-center" style={{maxHeight:"500px",paddingBottom:"3%",paddingTop:"3%"}}>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

import React,{Component} from "react";
import "../../css/widgets/widget.css";
export default class DialogBox4 extends Component {
  render(){
    return(
      <div class="modal d-flex align-items-center justify-content-center" style={{zIndex:1500,backgroundColor:"#0000009e"}} >
      <div class="card  widget bg-white" {...this.props}>
  <div class="card-header d-none">
  <div class="card-caption">
  <div class="card-icon">

  </div>
  <div class="card-title">
  <span class="main-title"></span>
    <span class="sub-title"></span>
  </div>
  </div>

  <div class="action-buttons">
  <a class="btn btn-circle d-none btn-icon-only"><i class="material-icons">cloud_download</i></a>
    <a class="btn btn-circle  btn-icon-only" onClick={()=>this.props.closeHandler()}><i class="fa fa-times"></i></a>

  </div>
  </div>
  <div class="card-body pt-4 pb-2 align-items-flex-center d-flex justify-content-start" style={{paddingTop:"4px",paddingBottom:"4px"}}>
  {this.props.msg}
  </div>
  <div class="card-body pb-4 align-items-center d-flex justify-content-start" style={{paddingBottom:"4px",paddingTop:"0px"}}>
  {this.props.msg1}
  </div>
 <div class="card-footer btn-group btn-group-spacer bg-white d-flex justify-content-end align-items-center" style={{maxHeight:"60px"}}>
  {this.props.children}
  </div>
</div>
  </div>
    )
  }
}

import moment from 'moment';
import momentTZ from 'moment-timezone';
import {BACKGROUND_COLORS,SPLITSCREEN_TYPES} from '../../constants/CONSTANTS';
import i18n from "../i18n";

export function formatDataForScheduler (schedules) {

	let outputData = [];
	let startTimeSlot, endTimeSlot = null;
	let id=0, title="";

	schedules.map((schedule,i)=>{
		Object.keys(schedule.schedule).map((type,i)=>{
			if(schedule.schedule[type].length>0 && type=="roundRobin"){
				console.log("roundrobin="+schedule.schedule[type]);
				schedule.schedule[type].map((data,i)=>{
					console.log("data.adGroup="+data.adGroup)
					if(!!data.playlistName){
						title = data.playlistName;
					}
					else if( !(!!data.playlistName) && !!data.adGroup && (data.adGroup.indexOf("INSTAGRAM")!==-1 || data.adGroup.indexOf("TWITTER")!==-1)){
						title = data.adGroup.replace("|"," ");
					} else{
						title = data.adName.substring(data.adName.indexOf("-")+1,data.adName.length);//data.adName;
					}
					outputData.push({
						startDate: moment(schedule.scheduleDate).format('YYYY-MM-DD 00:00:00'),
						endDate : moment(schedule.scheduleDate).format('YYYY-MM-DD 23:59:59'),
						title, //used for display
						adName:!!data.playlistName?data.playlistName:data.adName, //used to identify ad when deleting
						priorityId:2,//to set CSS
						allDay:true,
						slot:type,
						_id:schedule._id,
						id,
						isPlaylist : !!data.playlistName
					})
					id = parseInt(id)+1;
				})
				
			} else if(schedule.schedule[type].length>0 && type!="roundRobin"){
				console.log("not roundrobin ="+type);
				endTimeSlot = type.substring(4);
				startTimeSlot = parseInt(endTimeSlot)-1
				console.log("startTimeSlot="+startTimeSlot+" endTimeSlot="+endTimeSlot)
				schedule.schedule[type].map((data,i)=>{
					if(!!data.playlistName){
						title = data.playlistName;
					}
					else if(!(!!data.playlistName) && !!data.adGroup && (data.adGroup.indexOf("INSTAGRAM")!==-1 || data.adGroup.indexOf("TWITTER")!==-1)){
						title = data.adGroup.replace("|"," ");
					} else{
						title = data.adName.substring(data.adName.indexOf("-")+1,data.adName.length);//data.adName;
					}
					outputData.push({
						startDate: moment(schedule.scheduleDate).format('YYYY-MM-DD') + " "+startTimeSlot+":00:00",
						endDate : moment(schedule.scheduleDate).format('YYYY-MM-DD') +  " "+endTimeSlot+":00:00",
						title, //used for display
						adName:!!data.playlistName?data.playlistName:data.adName, //used to identify ad when deleting
						priorityId:1,//to set CSS,
						slot : type,
						_id:schedule._id,
						id,
						isPlaylist : !!data.playlistName
					})
					id = parseInt(id)+1;
				})
			}
			
		});
	
	});

	return outputData;
  }

  /*time bet now and last upload<=2 - ON
time bet now and last upload<=10 - IDLE
else OFF*/
  export function formatDataForMyScreens (screens,filterParams) {
	const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
	console.log("timeZone="+timeZone);
	console.log("***filterParams="+JSON.stringify(filterParams))
	let outputData = [];
	let status="", lastDataRecorded="", diff;
	screens.map((screen)=>{
		// console.log("***diff="+moment().utcOffset("+05:30").diff(
		// moment().format("YYYY-MM-DD").toString()+"T"+moment(screen.screenData.lastModified, 'YYYY-MM-DD HH:mm:ss').add(5,'hours').add(30,'minutes').format("HH:mm:ss")+"+05:30"
		// ,"minutes"))
		/*status = (screen.screenData  && (moment(screen.screenData.lastModified,'YYYY-MM-DD').diff(moment(),'days')===0)  
                              && Object.keys(screen.screenData.nowPlaying).length>0 )? moment().utcOffset("+05:30").diff(
                                moment().format("YYYY-MM-DD").toString()+"T"+moment(screen.screenData.nowPlaying.starttime, 'hh:mm:ss A').format('HH:mm:ss')+"+05:30"
                                 ,"minutes")<=2?

				   "ON":moment().utcOffset("+05:30").diff(
					moment().format("YYYY-MM-DD").toString()+"T"+moment(screen.screenData.nowPlaying.starttime, 'hh:mm:ss A').format('HH:mm:ss')+"+05:30"
					 ,"minutes")<=10?"IDLE":"OFF":"OFF";*/
			/*status = (screen.screenData  && (moment(screen.screenData.lastModified,'YYYY-MM-DD').diff(moment(),'days')===0)  
			&& Object.keys(screen.screenData.nowPlaying).length>0 )? moment().utcOffset("+05:30").diff(
			moment().format("YYYY-MM-DD").toString()+"T"+moment(screen.screenData.lastModified, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')+"+05:30"
			,"minutes")<=5?
		  "ON":"OFF":"OFF";		*/
		  console.log("***1="+momentTZ.tz(moment().format("YYYY-MM-DD HH:mm:ss"), timeZone).clone().tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss"))
		  diff = screen.screenData?momentTZ.tz(moment().format("YYYY-MM-DD HH:mm:ss"), timeZone).clone().tz("Asia/Kolkata").diff(
			momentTZ.tz(moment().format("YYYY-MM-DD HH:mm:ss"), timeZone).clone().tz("Asia/Kolkata").format("YYYY-MM-DD").toString()+"T"+moment(screen.screenData.lastModified, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')+"+05:30",'minutes')
			:-1;
		  status = (screen.screenData  && (momentTZ.tz(moment(screen.screenData.lastModified, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"), 'Asia/Kolkata').clone().tz(timeZone).diff(moment(),'days')===0)  
			&& Object.keys(screen.screenData.nowPlaying).length>0 )? diff<=5 && diff>=0?
		  "ON":"OFF":"OFF";
		if(screen.screenData){
			console.log("***2="+momentTZ.tz(moment().format("YYYY-MM-DD HH:mm:ss"), timeZone).clone().tz("Asia/Kolkata").format("YYYY-MM-DD").toString()+"T"+moment(screen.screenData.lastModified, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss'))
			console.log("***diff="+momentTZ.tz(moment().format("YYYY-MM-DD HH:mm:ss"), timeZone).clone().tz("Asia/Kolkata").diff(momentTZ.tz(moment().format("YYYY-MM-DD HH:mm:ss"), timeZone).clone().tz("Asia/Kolkata").format("YYYY-MM-DD").toString()+"T"+moment(screen.screenData.lastModified, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')+"+05:30",'minutes'))
			console.log("ISODATE to moment="+moment().format("YYYY-MM-DD HH:mm:ss"))
		}
		//lastDataRecorded=screen.screenData? moment(screen.screenData.lastModified,'YYYY-MM-DD HH:mm:ss').format("DD-MM-YYYY HH:mm"):""
		// if(screen.screenData){
		// 	console.log("ISODATE to moment="+moment(screen.screenData.lastModified, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"))
		// }
		
		lastDataRecorded=screen.screenData? momentTZ.tz(moment(screen.screenData.lastModified, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"), 'Asia/Kolkata').clone().tz(timeZone).format("YYYY-MM-DD HH:mm"):"";
		console.log(screen.screenName, status, screen.screenType, screen.spotCode,'a'+lastDataRecorded+'b');
		// console.log("filterParams.lastDataRecorded="+filterParams.lastDataRecorded);
		// console.log("****"+screen.scheduleChangeDate)
		if(filterParams.lastDataRecorded==null || (filterParams.lastDataRecorded!==null && filterParams.lastDataRecorded=="0" && screen.scheduleChangeDate==null)
		|| (filterParams.lastDataRecorded!==null && filterParams.lastDataRecorded=="1" && screen.scheduleChangeDate!==null)){
			//console.log("in output screen="+screen.screenName)
			outputData.push({
				id:screen._id,
				screenName:screen.screenName,
				syncGroup:screen.syncGroup && screen.syncGroup.syncGroupStatus=="0"?screen.syncGroup:null,
				status,
				screenType : screen.screenType,
				zone : screen.zone,
				location:screen.spotCode,
				lastDataRecorded,
				slave:screen.slave!==null?screen.slave:" ",
				ticker:screen.ticker!==null?screen.ticker:" ",
				layout:screen.layout!==null?screen.layout:" ",
				layoutForDisplay:SPLITSCREEN_TYPES[screen.layout],
				philipsAndroid5:screen.philipsAndroid5,
				hardwareRotation:screen.hardwareRotation
			})
		}
		
	});
	console.log("to show screens="+JSON.stringify(outputData))
	return outputData;
  }
  //added adName as parameter..if ad name is selected is filter show ad name else show ad group
  export function formatDataForAdReport(adsData,adName){
	
	var adsArr=[],returnArr = [];
	var dateArr=adsData[0];
	var adGroupsArr=adsData[1];
	var size = adGroupsArr.length;
	var durationForEachAdGroupArr=adsData[2];
	adGroupsArr.map((ad,index)=>{
		adsArr.push({"label":adName!==""?adName:ad,"backgroundColor":BACKGROUND_COLORS[index],"stack":"2","data":durationForEachAdGroupArr.map((obj)=>{return obj[index]})})
	});
	console.log("adsArr="+JSON.stringify(adsArr))
	returnArr.push(dateArr,adsArr);
	return returnArr;
  }

  //only get first 7 max ad groups..rest grouped in Others
  export function formatDataForUtilGraph(adsData){
	var adGroupsArr=adsData[1];
	var dataArr = adsData[2];
	var returnArr = [],totalArr=[],backgroundColor=[],total=0;
	
	for(var i=0;i<adGroupsArr.length;i++){
		totalArr[i]=0;
	}

	dataArr.map((obj,index)=>{
		for(var i=0;i<adGroupsArr.length;i++){
			totalArr[i] += Math.round(obj[i].toFixed(2));
			total+=Math.round(obj[i].toFixed(2));
		}
		
	});
	//console.log("totalArr="+totalArr);
	totalArr = totalArr.map((obj)=>{
		return Math.round(obj/total*100);
	})

	
	console.log("adGroupsArr="+adGroupsArr)
	console.log("totalArr="+totalArr);

	//Arrange in descending order of totals
	var arrayToSort = [];
	for(var i=0;i<adGroupsArr.length;i++){
		arrayToSort.push({adGroup:adGroupsArr[i],total:totalArr[i]});
	}
	
	console.log("arrayToSort="+JSON.stringify(arrayToSort))
	arrayToSort.sort((a,b)=>{
		return (b.total-a.total)
	});
	console.log("arrayToSort="+JSON.stringify(arrayToSort));
	var len=arrayToSort.length>7?8:arrayToSort.length;
	var returnTotalArr = [],adGroupReturnArr=[];
	for(var i=0;i<len;i++){
		returnTotalArr[i]=0;
	}
	arrayToSort.map((obj,index)=>{
		if(index<8){
			returnTotalArr[index] = obj.total;
			adGroupReturnArr[index] = obj.adGroup;
		}else{
			returnTotalArr[7]+=obj.total;
			adGroupReturnArr[7] = i18n.t("overview.others");
		}
	});
	console.log("returnTotalArr="+returnTotalArr)
	console.log("adGroupReturnArr="+adGroupReturnArr);
	//remove elements with 0 total
	var indexOfElement = -1,size = adGroupReturnArr.length;
	var found = returnTotalArr.find((obj,index)=>{
		if(obj==0){
			indexOfElement = index;
			return true;
			
		}
	});
	
	if(indexOfElement!==-1){
		size = indexOfElement;
	}

	for(var i=0;i<size;i++){
		backgroundColor[i] = BACKGROUND_COLORS[i];
	}

	returnArr.push(adGroupReturnArr.slice(0,size),returnTotalArr.slice(0,size),backgroundColor);
	return returnArr;
  }

  export function formatDataForDeleteLicenses  (screens) {
	console.log("***screens="+JSON.stringify(screens))
	var returnArr = screens.map((screen)=>{
		return {screenID:screen._id,screenName:screen.screenName,orientation:screen.zone,deviceIMEI:screen.deviceIMEI,screenType:screen.screenType,
		location:screen.spotCode};	
	});
	console.log("***returnArr="+JSON.stringify(returnArr))
	return returnArr;
  }
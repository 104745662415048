import React from "react"; 
 
function Success() { 
  return ( 
    <div style={{height:"100%",width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
      <h2>Thanks for your order!</h2> 
      <h4>Your payment is successful.</h4> 
      <p> 
        We appreciate your business! If you have any questions, please email us 
        at &nbsp;
        <a href="mailto:support@intelisa.in">support@intelisa.in</a>. 
        <br/>
        <div style={{textAlign:"center"}}><a href="/#/login">Login to Intelisa</a></div>
      </p> 
      <div> 
      </div> 
      </div>
  ); 
} 
 
export default Success;